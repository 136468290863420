import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Checklists from "../views/Checklists/Checklists.vue";
import ChecklistsBase from "../views/Checklists/ChecklistsBase.vue";
import JobsList from "../views/Checklists/JobList.vue";
import Main from "../views/Main.vue";
import PreWork from "../views/Checklists/PreWork.vue";
import RoadTest from "../views/Checklists/RoadTest.vue";
import ExtraMedia from "../views/Checklists/ExtraMedia.vue";
import Workshop from "../views/Checklists/Workshop.vue";
import PostWork from "../views/Checklists/PostWork.vue";
import FinalRoadTest from "../views/Checklists/FinalRoadTest.vue";

import ReceptionBase from "../views/Reception/ReceptionBase.vue";
import Reception from "../views/Reception/Reception.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: {
			guest: true,
		},
	},
	{
		path: "/checklists",
		name: "ChecklistsBase",
		component: ChecklistsBase,
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: "",
				name: "Checklists",
				component: Checklists,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "joblist/:type",
				name: "JobsList",
				component: JobsList,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "prework/:id",
				name: "PreWork",
				component: PreWork,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "roadtest/:id",
				name: "RoadTest",
				component: RoadTest,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "workshop/:id",
				name: "Workshop",
				component: Workshop,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "extramedia/:id",
				name: "ExtraMedia",
				component: ExtraMedia,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "postwork/:id",
				name: "PostWork",
				component: PostWork,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "finalroadtest/:id",
				name: "FinalRoadTest",
				component: FinalRoadTest,
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
	{
		path: "/reception",
		name: "ReceptionBase",
		component: ReceptionBase,
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: "",
				name: "Reception",
				component: Reception,
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
        let data = JSON.parse(localStorage.getItem("mainStore"));
        if(!data) {
			console.log('is /')
            next({ path: "/" });
        }
		if (data.authUser == null) {
			console.log('is / 2')
			next({ path: "/" });
		} else {
			next();
		}
	} else if (to.matched.some((record) => record.meta.guest)) {
        let data = JSON.parse(localStorage.getItem("mainStore"));
        if(data && data.authUser !== null) {
            next({ path: "/checklists" });
        } else {
            next();
        }
	} else {
		next();
	}
});
export default router;
