<template>
    <div class="numinput flex items-center">
        <span class="pointer-events-auto border border-color px-2 py-1 w-10 text-center rounded rounded-r-none border-r-0" @click="Decrement">-</span>
        <span class="border border-color px-2 py-1 w-10 text-center">{{value}}</span>
        <span class="pointer-events-auto border border-color px-2 py-1 w-10 text-center rounded rounded-l-none border-l-0" @click="Increment">+</span>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Number
        },
        min: {
            type: Number,
            default: 1
        },
        max: {
            type: Number,
            default: 99999
        }
    },
    data() {
        return {
            value: 1,
        }
    },
    mounted() {
        this.value = this.modelValue;
    },
    methods: {
        Decrement() {
            if(this.value > this.min) {
                this.value--
            }
        },
        Increment() {
            if(this.value < this.max) {
                this.value++
            }
        }
    },
    watch: {
        value(val) {
            this.$emit('update:modelValue', this.value)
        }
    }
}
</script>

<style>

</style>
