import {defineStore} from "pinia";

const initialState = {
    customerId: null,
    jobId: null,
    reg: '',
    name: '',
    email: '',
    jobOwner: '',
    tuningOwner: '',
    contactNum: '',
    contactMethod: 'Phone',
    customerLocation: '',
    customerLocationConfirmed: 'No',
    customerPresent: '',
    checkCompletedBy: null,
    oldParts: 'NA',
    smokeLevel: '',
    lockingWheelNutLocation: 'NA',
    lockingWheelNutLocationOther: '',
    dashcamFitted: 'No',
    hasBlackbox: 'No',
    hasEmissionWork: 'No',
    responsibilityConfirmed: 'Decline',
    hasGhostAlarm: 'No',
    hasGhostAlarmCode: '',
    folderId: '',
    jobsheetLink: '',
    partsConfirmed: 'No',
    tuningConfirmed: 'No',
    deposit: '',
    authorisedCosts: '0',
    tuningJobLink: '',
    expectedPower: '',
    launch: '',
    launchRpm: '',
    hardcut: '',
    hardcutRpm: '',
    generalConditionOfTyres: '',
    generalConditionOfTyresDesc: '',
    tyreWidth: '25',
    tyreDepth: '135',
    tyreDiameter: '13',
    tyreBrand: '',
    lightsOnDashboardCheck: '',
    serviceIndicatorPresent: '',
    hasCourtesyCar: 'No',
    currentMileage: '',
    turboNoise: '',
    bookingFormID: '',
    customerSignature: '',
    notes: '',
    dashLights: [],
    exteriorPhotos: [],
    interiorPhotos: [],
    courtesyCarPhotos: [],
    make: null,
    model: null,
}
export const usePreWorkStore = defineStore('preWorkStore',{
    /*state: () => {
        return {...initialState}
    },*/
    state: () => ({ ...initialState }),
    actions: {
        async clearAll() {
            localStorage.removeItem('preWorkStore')
            Object.assign(this, {...initialState})
        }
    },
    persist: false,
});
