<template>
    <div class="bg-white h-full w-full border-t">
        <div class="h-full flex justify-between">
            <span class="tab-link h-full w-full bg-white flex items-center justify-center" :class="{'active': isTabActive('checklists')}" @click="GoTo('checklists')">
                <div>
                    <i class="ic ic-list-alt"></i>
                    <span class="block">All Checklists</span>
                </div>
            </span>
            <!--<span class="tab-link h-full w-1/2 bg-white flex items-center justify-center" :class="{'active': isTabActive('reception')}" @click="GoTo('reception')">
                <div>
                    <i class="ic ic-store-alt"></i>
                    <span class="block">Reception</span>
                </div>
            </span>-->
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$nextTick(() => {
        });
    },
    methods: {
        isTabActive(tab) {
            if (this.$route.path.includes(tab)) {
                return true;
            }
            return false;
        },
        GoTo(tab) {
            if(["PreWork", "RoadTest", "Workshop", "PostWork", "FinalRoadTest", "ExtraMedia"].includes(this.$route.name)) {
                const conf = confirm("Are you sure you want to leave the current checklist?")
                if(conf) {
                    localStorage.removeItem("preWorkStore")
                    localStorage.removeItem("postWorkStore")
                    localStorage.removeItem("workshopStore")
                    localStorage.removeItem("roadTestStore")
                    localStorage.removeItem("finalRoadTestStore")
                    localStorage.removeItem("extraPhotosVideosStore")
                    this.$router.push('/'+tab);
                }
            } else {
                this.$router.push('/'+tab);
            }
        }
    },
    watch: {
        "$route.name"(val) {
            console.log(val)
        }
    }
}
</script>

<style lang="scss" scoped>
.tab-link.active {
    i {
        color: rgb(89, 89, 216);
    }
    span {
        color: rgb(89, 89, 216);
        font-weight: bold;
    }
}
</style>
