<template>
    <svg :viewBox="viewbox" fill="currentColor" class="remix" :class="sizeClass" :style="{height: height}" v-if="name"><use :xlink:href="iconSpriteLocation + '#' + name"></use></svg>
    <span class="material-icons" :class="sizeClass" v-else>{{icon}}</span>
</template>

<script>
var iconSpriteLocation = require('../../assets/remixicon.symbol.svg');
export default {
    props: {
        name: {
            type: String
        },
        icon: {
            type: String
        },
        viewbox: {
            type: String,
            default: "0 0 24 24"
        },
        height: {
            type: String,
            default: "30px"
        },
        size: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            iconSpriteLocation: iconSpriteLocation
        }
    },
    computed: {
        sizeClass() {
            if(this.size == "xs") {
                return "text-xs";
            } else if(this.size == "sm") {
                return "text-sm";
            } else if(this.size == "md") {
                return "text-base";
            } else if(this.size == "lg") {
                return "text-lg";
            } else if(this.size == "xl") {
                return "text-xl";
            } else {
                return "";
            }
        }
    },
    mounted() {
    }
}
</script>

<style>

</style>