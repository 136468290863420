<template>
    <div class="h-full">
        <ion-progress-bar type="indeterminate" v-if="isUploading"></ion-progress-bar>
        <vue-slider class="zoom-slider" v-model="currentZoom" v-bind="sliderOpts"></vue-slider>
        <div class="flex justify-center fixed bottom-4 w-full">
            <div class="shadow-lg rounded-lg p-3 flex items-center justify-center gap-16" style="width:300px;background:#282928">
                <q-icon icon="arrow_back" style="font-size: 2em; color: white" @click="CloseCamera" />
                <span style="height:60px; width:60px;border-radius:30px; border:2px solid white;" class="bg-black rounded-borders flex items-center justify-center" @click="TakePicture">
                    <span style="height:40px; width:40px;border-radius:20px;" class="bg-white block rounded-borders pointer-events-none"></span>
                </span>
                <q-icon icon="flash_off" v-if="flashOn" style="font-size: 2em; color: white" @click="ToggleFlash" />
                <q-icon icon="flash_on" v-else style="font-size: 2em; color: white" @click="ToggleFlash" />
            </div>
        </div>

        <div class="campreview fixed top-0 left-0 bottom-0 right-0 z-50" v-if="imgPreview">
            <img :src="'data:image/png;base64, ' + imgPreview">
            <div class="flex justify-center fixed bottom-4 w-full">
                <div class="shadow-lg rounded-lg p-3 mx-auto flex items-center justify-center gap-16" style="width:300px;background:#282928;z-index: 60">
                    <i style="font-size: 4em;" class="linearicons linearicons-cross-circle text-red-500" @click="imgPreview = null" />
                    <i style="font-size: 4em;" class="linearicons linearicons-checkmark-circle text-green-500" @click="SaveImage" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {
    defineComponent
} from 'vue';
import {
    useBackButton,
    IonProgressBar,
    alertController
} from '@ionic/vue';
import { CameraPreview, CameraPreviewOptions } from '@capacitor-community/camera-preview';
import {useMainStore} from "@/store/mainStore";
export default defineComponent({
    name: 'Camera',
    components: {
        IonProgressBar
    },
    props: {
    },
    data() {
        return {
            flashOn: false,
            imgPreview: null,
            maxZoom: 0,
            currentZoom: 0,
            step: 1,
            images: [],
            sliderOpts: {
                dotSize: 14,
                width: 6,
                height: 200,
                contained: false,
                direction: 'btt',
                data: null,
                dataLabel: 'label',
                dataValue: 'value',
                min: 0,
                max: 0,
                interval: 1,
                disabled: false,
                clickable: true,
                duration: 0.5,
                adsorb: true,
                lazy: false,
                tooltip: 'active',
                tooltipPlacement: 'top',
                tooltipFormatter: void 0,
                useKeyboard: false,
                keydownHook: null,
                dragOnClick: false,
                enableCross: true,
                fixed: false,
                minRange: void 0,
                maxRange: void 0,
                order: true,
                marks: false,
                dotOptions: void 0,
                dotAttrs: void 0,
                process: true,
                dotStyle: void 0,
                railStyle: void 0,
                processStyle: void 0,
                tooltipStyle: void 0,
                stepStyle: void 0,
                stepActiveStyle: void 0,
                labelStyle: void 0,
                labelActiveStyle: void 0,
            },
            isUploading: false
        }
    },
    emits: ["cameraClosed"],
    mounted() {
        useBackButton(10, () => {
            this.CloseCamera()
        });
        this.OpenCamera();
        this.sliderOpts.max = 4
    },
    methods: {
        OpenCamera() {
            const options = {
                x: 0,
                y: 0,
                width: window.screen.width,
                height: window.screen.height,
                camera: 'rear',
                tapPhoto: true,
                toBack: true,
                alpha: 1
            };
            CameraPreview.startCamera(options);
            this.$store.commit('mainStore/setCameraOpen', true)
            setTimeout(() => {
                CameraPreview.getMaxZoom().then(maxZoom => {
                    this.maxZoom = maxZoom;
                    this.sliderOpts.max = maxZoom;
                    console.log(maxZoom);
                });
                CameraPreview.getZoom().then(zoom => {
                    this.currentZoom = zoom;
                    console.log(zoom);
                });
            }, 1000);
        },
        CloseCamera() {
            CameraPreview.stopCamera();
            this.$emit('cameraClosed', this.images);
            this.$store.commit('mainStore/setCameraOpen', false)
        },
        ToggleFlash() {
            if (this.flashOn) {
                this.flashOn = false;
                CameraPreview.setFlashMode(CameraPreview.FLASH_MODE.OFF);
            } else {
                this.flashOn = true;
                CameraPreview.setFlashMode(CameraPreview.FLASH_MODE.ON);
            }
        },
        TakePicture() {
            CameraPreview.takePicture().then(base64PictureData => {
                this.imgPreview = base64PictureData[0]
            }).catch(err => {
                console.log(err)
            })
        },
        async SaveImage() {
            var imgTitle = useMainStore().authUser.label.replace(' ', '') + "_" + moment().format('x') + "_reception";
            const obj = {
                id: this.step,
                name: imgTitle,
                image: this.imgPreview,
            }
            this.images.push(obj);
            this.imgPreview = null
        },
        ImgComplete(id) {
            const find = this.images.find(el => el.id == id);
            if (find) {
                return true;
            } else {
                return false;
            }
        },
        UploadImage(obj) {
            this.isUploading = true;
            var uplUrl = this.$apiUrl + "/v2/checklist/job/photos/upload";
            var blob = this.b64toBlob(obj.image);
            var formData = new FormData;
            formData.append('file', blob);
            formData.append('folderId', this.folderId);
            formData.append('name', obj.name);
            formData.append('type', obj.type);
            formData.append('jobid', this.jobid);
            this.$axios.post(uplUrl, formData).then(resp => {
                var indx = this.images.findIndex(el => el.name === obj.name);
                if (indx > -1) {
                    this.images[indx].path = "https://drive.google.com/file/d/" + resp.data.data.id + "/view?usp=drivesdk"
                }
                this.isUploading = false;
            }).catch(err => {
                console.log(err)
                this.isUploading = false;
            })
        },
        onBackKeyDown(e) {
            console.log('bb pressed')
            e.preventDefault()
            e.stopImmediatePropagation();
            e.stopPropagation();
            return false;
        },
        beforeDestroy() {
            this.$store.commit('mainStore/setCameraOpen', false)
        },
        b64toBlob(b64Data, contentType = '', sliceSize = 512) {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, {
                type: contentType
            });
            return blob;
        }
    },
    watch: {
        currentZoom(val) {
            CameraPreview.setZoom(val);
        }
    }
})
</script>

<style lang="scss">
.vue-slider.zoom-slider {
    position: absolute;
    right: 20px;
    top: 50%;
    bottom: 0;
}
</style>
