<template>
<div>
    <label class='block tracking-wide text-gray-700 text-xs font-bold mb-1' v-if="label !== ''">{{label}}</label>
    <div class="flex flex-nowrap items-center">
        <input class="appearance-none block w-full text-gray-700 border border-gray-400 rounded-md leading-tight focus:outline-none focus:border-gray-500" type="number" :disabled="disabled" :readonly="readonly" :value="val" :placeholder="placeholder" @blur="OnBlur" @input="OnInput" @focus="OnFocus" @change="OnChange" :class="[classes, {
                    'font-bold': bold,
                    'bg-red-500 text-white': parseInt(val) < 20,
                    'bg-yellow-500 text-white': parseInt(val) >= 20 && parseInt(val) < 28,
                    'bg-green-500 text-white': parseInt(val) >= 28
                }]" :name="name" :min="min">
        <div v-if="showicon">
            <q-icon name="check_circle" class="text-green-600 text-3xl ml-3" v-if="val !== ''" />
            <q-icon name="cancel" class="text-red-600 text-3xl ml-3" v-else />
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        modelValue: {
            required: true,
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'md',
            validator: function (value) {
                return ['xs', 'sm', 'md', 'lg'].indexOf(value) !== -1
            }
        },
        shadow: {
            type: String,
            default: 'default',
            validator: function (value) {
                return ['none', 'default', 'sm', 'md', 'lg'].indexOf(value) !== -1
            }
        },
        textsize: {
            type: String,
            default: 'default',
            validator: function (value) {
                return ['default', 'sm', 'md', 'lg'].indexOf(value) !== -1
            }
        },
        min: {
            type: Number,
            default: 0
        },
        bold: {
            type: Boolean,
            default: false
        },
        showicon: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        val: ""
    }),
    computed: {
        classes() {
            var shadow = '';
            if (this.shadow === 'default') {
                shadow = 'shadow';
            } else if (this.shadow === 'sm') {
                shadow = 'shadow-sm';
            } else if (this.shadow === 'md') {
                shadow = 'shadow-md';
            } else if (this.shadow === 'lg') {
                shadow = 'shadow-lg';
            }

            var readonly = '';
            if (this.readonly) {
                readonly = 'bg-gray-400';
            }

            var textsize = '';
            if (this.textsize !== 'default') {
                textsize = 'text-' + this.textsize
            }
            if (this.size === 'xs') {
                return 'py-2 px-1 text-xs ' + shadow + ' ' + textsize + ' ' + readonly;
            } else if (this.size === 'sm') {
                return 'py-2 px-2 text-xs ' + shadow + ' ' + textsize + ' ' + readonly;
            } else if (this.size === 'md') {
                return 'py-3 px-3 text-sm ' + shadow + ' ' + textsize + ' ' + readonly;
            } else if (this.size === 'lg') {
                return 'py-3 px-5 text-md ' + shadow + ' ' + textsize + ' ' + readonly;
            }
            return '';
        }
    },
    mounted() {
        this.val = this.modelValue;
    },
    methods: {
        OnInput(e) {
            this.val = e.target.value;
            this.$emit('input', e.target.value)
        },
        OnBlur(e) {
            this.$emit('blur', e)
        },
        OnChange(e) {
            this.$emit('change', e)
        },
        OnFocus(e) {
            this.$emit('focus', e)
        }
    },
    watch: {
        modelValue(val) {
            this.val = this.modelValue;
        },
        val(value) {
            this.$emit('update:modelValue', value)
        }
    },
}
</script>

<style>

</style>
