<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title class="font-bold" @click="ResetList">{{title}}</ion-title>
                <span slot="end" class="pr-5">
                    <user-chip></user-chip>
                </span>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true" ref="ioncontent">
            <ion-header collapse="condense">
                <ion-toolbar>
                    <ion-title size="large">Blank</ion-title>
                </ion-toolbar>
            </ion-header>
            <div id="container" class="h-full">
                <div class="grid grid-cols-2 h-full">
                    <q-card class="w-full h-full checklist-type-card checklist-type-card-left" :style="{minHeight: '150px' }" :class="{'disabled': !forms.meet_greet}" @click="GetJobs('mng')">
                        <template #main>
                            <div class="text-center">
                                <div class="bg-gray-50 flex items-center justify-center shadow-md mx-auto mb-2 w-20 h-20 rounded-lg overflow-hidden"><img :src="require('../../assets/images/checklist_logos/Meet and Greet.png')"></div>
                                <div><strong class="text-lg">Meet &amp; Greet</strong></div>
                            </div>
                        </template>
                    </q-card>

                    <q-card class="w-full h-full checklist-type-card checklist-type-card-right" :style="{minHeight: '150px' }" :class="{'disabled': !forms.road_test}" @click="GetJobs('rt')">
                        <template #main>
                            <div class="text-center">
                                <div class="bg-gray-50 flex items-center justify-center shadow-md mx-auto mb-2 w-20 h-20 rounded-lg overflow-hidden"><img :src="require('../../assets/images/checklist_logos/Road Test.png')"></div>
                                <div><strong class="text-lg">Road Test</strong></div>
                            </div>
                        </template>
                    </q-card>

                    <q-card class="w-full h-full checklist-type-card checklist-type-card-left" :style="{minHeight: '150px' }" :class="{'disabled': !forms.workshop_sheet}" @click="GetJobs('wc')">
                        <template #main>
                            <div class="text-center">
                                <div class="bg-gray-50 flex items-center justify-center shadow-md mx-auto mb-2 w-20 h-20 rounded-lg overflow-hidden"><img :src="require('../../assets/images/checklist_logos/Workshop Check.png')"></div>
                                <div><strong class="text-lg">Workshop</strong></div>
                            </div>
                        </template>
                    </q-card>

                    <q-card class="w-full h-full checklist-type-card checklist-type-card-right" :style="{minHeight: '150px' }" :class="{'disabled': !forms.evp_sheet}" @click="GetJobs('epv')">
                        <template #main>
                            <div class="text-center">
                                <div class="bg-gray-50 flex items-center justify-center shadow-md mx-auto mb-2 w-20 h-20 rounded-lg overflow-hidden"><img :src="require('../../assets/images/checklist_logos/Add Photos - Videos.png')"></div>
                                <div><strong class="text-lg">Extra Media</strong></div>
                            </div>
                        </template>
                    </q-card>

                    <q-card class="w-full h-full checklist-type-card checklist-type-card-left" :style="{minHeight: '150px' }" :class="{'disabled': !forms.post_work_sheet}" @click="GetJobs('pwc')">
                        <template #main>
                            <div class="text-center">
                                <div class="bg-gray-50 flex items-center justify-center shadow-md mx-auto mb-2 w-20 h-20 rounded-lg overflow-hidden"><img :src="require('../../assets/images/checklist_logos/Post Work Check.png')"></div>
                                <div><strong class="text-lg">Post Work</strong></div>
                            </div>
                        </template>
                    </q-card>

                    <q-card class="w-full h-full checklist-type-card checklist-type-card-right" :style="{minHeight: '150px' }" :class="{'disabled': !forms.final_road_test}" @click="GetJobs('frt')">
                        <template #main>
                            <div class="text-center">
                                <div class="bg-gray-50 flex items-center justify-center shadow-md mx-auto mb-2 w-20 h-20 rounded-lg overflow-hidden"><img :src="require('../../assets/images/checklist_logos/Final Road Test.png')"></div>
                                <div><strong class="text-lg">Final Road Test</strong></div>
                            </div>
                        </template>
                    </q-card>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSearchbar,
    loadingController
} from '@ionic/vue';
import {
    defineComponent
} from 'vue';
import {useMainStore} from "@/store/mainStore"
import {usePreWorkStore} from "@/store/preWorkStore";
import {useRoadTestStore} from "@/store/roadTestStore";
import {useWorkshopStore} from "@/store/workshopStore";
import {useExtraPhotosVideosStore} from "@/store/extraPhotosVideosStore";
import {usePostWorkStore} from "@/store/postWorkStore";
import {useFinalRoadTestStore} from "@/store/finalRoadTestStore";
export default defineComponent({
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar
    },
    data() {
        return {
            mainStore: useMainStore(),
            jobs: [],
            allJobs: [],
            forms: {
                meet_greet: false,
                road_test: false,
                workshop_sheet: false,
                evp_sheet: false,
                post_work_sheet: false,
                final_road_test: false
            },
            elemHeight: "33.33%",
            title: "Darkside Developments"
        }
    },
    mounted() {
        this.jobs = [];
        this.allJobs = [];
        this.$nextTick(() => {
            if (this.mainStore.authUser) {
                this.GetAllowedForms();
            }
        });
    },
    methods: {
        async GetJobs(type) {
            /*const loading = await loadingController.create({
                cssClass: 'my-custom-class',
                message: 'Please wait...',
                duration: 100000,
            });
            await loading.present();
            this.type = type;
            this.$axios.get(this.$apiUrl + '/v2/checklist/jobs-type?type=' + type).then((resp) => {
                this.allJobs = resp.data
                this.jobs = resp.data
                if(type === "mng") {
                    this.title = "Darkside Developments - Pre Work"
                }
                else if(type === "rt") {
                    this.title = "Darkside Developments - Road Test"
                }
                else if(type === "pwc") {
                    this.title = "Darkside Developments - Post Work"
                }
                else if(type === "frt") {
                    this.title = "Darkside Developments - Final Road Test"
                }
                else if(type === "wc") {
                    this.title = "Darkside Developments - Workshop"
                } else if(type === "epv"){
                    this.title = "Darkside Developments - Extra Media"
                } else {
                    this.title = "Darkside Developments"
                }
                loading.dismiss()
            }).catch((err) => {
                console.log(err)
                loading.dismiss()
            })*/

            usePreWorkStore().$reset();
            useRoadTestStore().$reset();
            useWorkshopStore().$reset();
            useExtraPhotosVideosStore().$reset();
            usePostWorkStore().$reset();
            useFinalRoadTestStore().$reset();
            //await useMainStore().clearAllStores();

            this.$router.push('/checklists/joblist/'+type)
        },
        GetAllowedForms() {
            this.$axios.get(this.$apiUrl + '/v2/checklist/user/forms/allowed?id=' + this.mainStore.authUser.value).then((resp) => {
                if (resp.data.success) {
                    var d = resp.data.data;
                    this.forms.meet_greet = d.meet_greet
                    this.forms.road_test = d.road_test
                    this.forms.workshop_sheet = d.workshop_sheet
                    this.forms.evp_sheet = d.evp_sheet
                    this.forms.post_work_sheet = d.post_work_sheet
                    this.forms.final_road_test = d.final_road_test
                }
            }).catch(err => {

            })
        },
        Search(e) {
            var val = e.target.value
            if (val !== "") {
                var arr = this.allJobs.filter((item) => {
                    var str = item.id + "|" + item.name + "|" + item.VRVrm + "|" + item.VRMake + "|" + item.VRModel + "|" + item.phonenumber + "|" + item.email;
                    return val.toLowerCase().split(' ').every(v => str.toLowerCase().includes(v))
                })
                this.jobs = arr
            } else {
                this.jobs = this.allJobs
            }
        },
        GoTo(id) {
            if (this.type == 'mng') {
                this.$router.push({
                    path: `/checklists/prework/${id}`
                })
            } else if (this.type == 'rt') {
                this.$router.push({
                    path: `/checklists/roadtest/${id}`
                })
            } else if (this.type == 'wc') {
                this.$router.push({
                    path: `/checklists/workshop/${id}`
                })
            } else if (this.type == 'epv') {
                this.$router.push({
                    path: `/checklists/extramedia/${id}`
                })
            } else if (this.type == 'pwc') {
                this.$router.push({
                    path: `/checklists/postwork/${id}`
                })
            } else if (this.type == 'frt') {
                this.$router.push({
                    path: `/checklists/finalroadtest/${id}`
                })
            }
        },
        ResetList() {
            this.jobs = [];
            this.allJobs = [];
        }
    },
    watch: {},
});
</script>

<style lang="scss">
ion-card.disabled {
    pointer-events: none;
    position: relative;
}

ion-card.disabled::after {
    content: '\e96b';
    font-family: "Linearicons";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 3rem;
    z-index: 9;
    background: rgba(0, 0, 0, 0.7)
}
</style>
