<template>
    <div>
        <div class="flex">
            <div class="form-check flex items-center" v-for="(opt, indx) in options" :key="opt.value" :title="opt.value" :class="[opt.color.toLowerCase(), {'rounded-l': indx == 0, 'rounded-r': indx == options.length - 1}]">
                <label class="flex items-center form-check-label px-8 cursor-pointer" :class="{'py-2':padding, 'border border-gray-900': opt.color == 'White', 'rounded-r': indx == options.length - 1}">
                    <input type="radio" class="form-check-input w-3" :name="id" :value="opt.value" v-model="val">
                    <span v-if="opt.text">{{opt.text}}</span>
                </label>
            </div>
        </div>
        <div class="font-bold text-red-500 mt-1">{{message}}</div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            default: function () {
                return [{
                        color: 'Red',
                        value: 'Red'
                    },
                    {
                        color: 'Orange',
                        value: 'Orange'
                    },
                    {
                        color: 'Green',
                        value: 'Green'
                    }
                ]
            }
        },
        modelValue: {
            type: String
        },
        padding: {
            type: Boolean,
            default: false
        },
        v: Object
    },
    data: () => ({
        id: null,
        val: ''
    }),
    mounted() {
        this.val = this.modelValue;
        this.id = '_' + Math.random().toString(36).substr(2, 9);
    },
    watch: {
        modelValue(val) {
            this.val = this.modelValue;
        },
        val(value) {
            this.$emit('update:modelValue', value)
        }
    },
    computed: {
        message() {
            if (this.v && this.v.$error) {
                for (let key in this.$options.messages) {
                    if (this.v[key].$invalid === true) {
                        return this.$options.messages[key](this.v)
                    }
                }
                return null;
            } else {
                return null
            }
        }
    },
    messages: {
        required: v => 'This field is required',
        email: v => 'Must be a valid e-mail',
        alphaNum: v => 'May only contain letters and numbers',
        minLength: v => 'Must be at least ' + v.$params.minLength.min + ' characters',
        goodPassword: v => '', //The password rules are always displayed, so no need to show a message
        sameAs: v => 'Must be same as ' + v.$params.sameAs.eq
    }
}
</script>

<style>
.form-check.green {
    background-color: #59a145;
}

.form-check.yellow {
    background-color: #f7dd18;
}

.form-check.orange {
    background-color: #ff811a;
}

.form-check.red {
    background-color: #ee2b2b;
}
</style>
