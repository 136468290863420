<template>
    <ion-app>
        <a href="https://darkside-developments.com/vehiclechecklists/app/DSDChecklists.apk" target="_blank" v-if="updateAvailable" class="fixed top-3 z-50 bg-red-500 px-2 py-1 right-32 rounded cursor-pointer text-white">
            Update Available
        </a>
        <ion-tabs>
            <ion-router-outlet />
            <ion-tab-bar slot="bottom" v-if="$route.name !== 'Home'" v-show="!mainStore.cameraOpen">
                <tab-bar></tab-bar>
            </ion-tab-bar>
        </ion-tabs>
    </ion-app>
</template>

<script>
import {
    IonApp,
    IonTabs,
    IonIcon,
    IonLabel,
    IonBadge,
    IonTabBar,
    IonTabButton,
    IonRouterOutlet,
    loadingController
} from '@ionic/vue';
import axios from 'axios';
import {
    calendar,
    personCircle
} from 'ionicons/icons';
import {
    defineComponent
} from 'vue';
import { useMainStore } from "@/store/mainStore";
import {storeToRefs} from "pinia";
export default defineComponent({
    name: 'App',
    async mounted() {
        axios.get("https://darkside-developments.com/vehiclechecklists/app_version.php").then(resp => {
            const latestVersion = resp.data.version;
            this.updateAvailable = latestVersion !== window.app_version.toString();
        }).catch(err => {

        })
        /*var physicalScreenWidth = window.screen.width * window.devicePixelRatio;
        var physicalScreenHeight = window.screen.height * window.devicePixelRatio;
        console.log(physicalScreenWidth, physicalScreenHeight)
        console.log(window.screen.width, window.screen.height)*/
    },
    data() {
        return {
            loading: null,
            updateAvailable: false,
            mainStore: useMainStore(),
            cameraOpen: storeToRefs(useMainStore()).cameraOpen
        }
    },
    methods: {
    },
    setup() {
        return {
            calendar,
            personCircle,
        }
    },
    components: {
        IonApp,
        IonTabs,
        IonRouterOutlet,
        IonTabBar
    },
    watch: {
        cameraOpen(val) {
            console.log('camera: ', val)
            if (val) {
                document.getElementsByTagName('body')[0].classList.add('cameraOpen')
            } else {
                document.getElementsByTagName('body')[0].classList.remove('cameraOpen')
            }
        }
    }
});
</script>

<style scoped>
ion-tab-bar {
    height: 80px
}
</style>
<style>
.readonly {
    background: rgba(0, 0, 0, 0.05);
    pointer-events: none;
}
ion-loading.custom-loading {
    --background: #e3edff;
    --spinner-color: #1c6dff;

    color: #1c6dff;
}
</style>
