<template>
    <div class="h-full camera-view">
        <ion-progress-bar type="indeterminate" v-if="isUploading"></ion-progress-bar>
        <div class="px-4 pt-4" v-if="requiredImages.length > 0">
            <div class="bg-white rounded-lg p-3 shadow-lg">
                <div class="flex gap-3 flex-nowrap overflow-x-auto">
                    <div class="carpart" v-for="(img, indx) in requiredImages" :key="indx" style="flex: 0 0 10rem;" :class="{'active': step == img.id, 'complete': ImgComplete(img.id)}" @click="step = img.id">
                        <div class="absolute top-2 left-2">
                            <q-icon icon="radio_button_unchecked" size="xs" v-if="!ImgComplete(img.id)"></q-icon>
                            <q-icon icon="radio_button_checked" size="xs" v-else></q-icon>
                        </div>
                        <b>{{img.text}}</b>
                    </div>
                </div>
            </div>
        </div>

        <!--<vue-slider class="zoom-slider" v-model="currentZoom" v-bind="sliderOpts"></vue-slider>-->
        <div class="flex justify-center fixed bottom-4 w-full">
            <div class="shadow-lg rounded-lg p-3 flex items-center justify-center gap-16" style="width:300px;background:#282928">
                <q-icon icon="arrow_back" style="font-size: 2em; color: white" @click="CloseCamera" />
                <span style="height:60px; width:60px;border-radius:30px; border:2px solid white;" class="bg-black rounded-borders flex items-center justify-center" @click="TakePicture">
                    <span style="height:40px; width:40px;border-radius:20px;" class="bg-white block rounded-borders pointer-events-none"></span>
                </span>
                <q-icon icon="flash_off" v-if="flashOn" style="font-size: 2em; color: white" @click="ToggleFlash" />
                <q-icon icon="flash_on" v-else style="font-size: 2em; color: white" @click="ToggleFlash" />
            </div>
        </div>

        <div class="campreview fixed top-0 left-0 bottom-0 right-0 z-50" v-if="imgPreview">
            <img :src="'data:image/png;base64, ' + imgPreview">
            <div class="flex justify-center fixed bottom-4 w-full">
                <div class="shadow-lg rounded-lg p-3 mx-auto flex items-center justify-center gap-16" style="width:300px;background:#282928;z-index: 60">
                    <i style="font-size: 4em;" class="linearicons linearicons-cross-circle text-red-500" @click="imgPreview = null" />
                    <i style="font-size: 4em;" class="linearicons linearicons-checkmark-circle text-green-500" @click="SaveImage" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue';
import {alertController, IonProgressBar, useBackButton} from '@ionic/vue';
import { CameraPreview, CameraPreviewOptions } from '@capacitor-community/camera-preview';
import {useMainStore} from "@/store/mainStore";
export default defineComponent({
    name: 'Camera',
    components: {
        IonProgressBar
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        checkType: {
            type: String,
            required: true,
        },
        currentImages: {
            type: Array,
            required: true,
        },
        folderId: {
            type: String,
            required: true
        },
        jobid: {
            required: true
        },
        requiredImages: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            flashOn: false,
            imgPreview: null,
            maxZoom: 0,
            currentZoom: 0,
            step: 1,
            images: [],
            exteriorImageTypes: [{
                    id: 1,
                    name: "Windscreen"
                },
                {
                    id: 2,
                    name: "Front Drivers Corner"
                },
                {
                    id: 3,
                    name: "Front Drivers Wheel"
                },
                {
                    id: 4,
                    name: "Front"
                },
                {
                    id: 5,
                    name: "Front Passenger Corner"
                },
                {
                    id: 6,
                    name: "Front Passenger Wheel"
                },
                {
                    id: 7,
                    name: "Passenger Side"
                },
                {
                    id: 8,
                    name: "Rear Passenger Corner"
                },
                {
                    id: 9,
                    name: "Rear Passenger Wheel"
                },
                {
                    id: 10,
                    name: "Rear"
                },
                {
                    id: 11,
                    name: "Boot"
                },
                {
                    id: 12,
                    name: "Rear Drivers Corner"
                },
                {
                    id: 13,
                    name: "Rear Drivers Wheel"
                },
                {
                    id: 14,
                    name: "Drivers Side"
                },
                {
                    id: 15,
                    name: "Under Bonnet"
                },
                {
                    id: 16,
                    name: "Under Cover"
                },
            ],
            interiorImageTypes: [{
                    id: 1,
                    name: "Interior Front"
                },
                {
                    id: 2,
                    name: "Interior Rear"
                },
                {
                    id: 3,
                    name: "Clocks While Running"
                }
            ],
            sliderOpts: {
                dotSize: 14,
                width: 6,
                height: 200,
                contained: false,
                direction: 'btt',
                data: null,
                dataLabel: 'label',
                dataValue: 'value',
                min: 0,
                max: 0,
                interval: 1,
                disabled: false,
                clickable: true,
                duration: 0.5,
                adsorb: true,
                lazy: false,
                tooltip: 'active',
                tooltipPlacement: 'top',
                tooltipFormatter: void 0,
                useKeyboard: false,
                keydownHook: null,
                dragOnClick: false,
                enableCross: true,
                fixed: false,
                minRange: void 0,
                maxRange: void 0,
                order: true,
                marks: false,
                dotOptions: void 0,
                dotAttrs: void 0,
                process: true,
                dotStyle: void 0,
                railStyle: void 0,
                processStyle: void 0,
                tooltipStyle: void 0,
                stepStyle: void 0,
                stepActiveStyle: void 0,
                labelStyle: void 0,
                labelActiveStyle: void 0,
            },
            isUploading: false
        }
    },
    emits: ["cameraClosed"],
    mounted() {
        useBackButton(10, () => {
            this.CloseCamera()
        });
        this.step = 1;
        this.images = this.currentImages;
        this.OpenCamera();
        this.sliderOpts.max = 4
    },
    methods: {
        async OpenCamera() {
            const options = {
                x: 20,
                y: 0,
                width: window.screen.width - 40,//1080,
                height: window.screen.height,//1920,
                position: 'rear',
                toBack: true,
                enableZoom: true,
            };
            await CameraPreview.start(options);
            useMainStore().setCameraOpen(true)
        },
        CloseCamera() {
            CameraPreview.stop();
            this.$emit('cameraClosed', this.images);
            useMainStore().setCameraOpen(false)
        },
        ToggleFlash() {
            if (this.flashOn) {
                this.flashOn = false;
                CameraPreview.setFlashMode({ flashMode: "torch" });
            } else {
                this.flashOn = true;
                CameraPreview.setFlashMode({ flashMode: "off" });
            }
        },
        TakePicture() {
            CameraPreview.capture({
                quality: 100,
                width: 1200,//1080,
                height: 1920
            }).then(base64PictureData => {
                this.imgPreview = base64PictureData.value
            }).catch(err => {
                console.log(err)
            })
        },
        async SaveImage() {
            let imgName;
            let canUpload = true;
            imgName = this.requiredImages.find(el => el.id === this.step);
            let imgTitle = imgName.text
            if(imgTitle === "Extra") {
                await this.presentAlertPrompt().then(resp => {
                    imgTitle = resp.filename;
                }).catch(err => {
                    canUpload = false;
                })
            }
            if(canUpload) {
                const obj = {
                    id: this.step,
                    name: imgTitle,
                    image: this.b64toBlob(this.imgPreview),//this.imgPreview,
                    type: this.checkType
                }
                this.images.push(obj);
                //this.UploadImage(obj);
                this.imgPreview = null
                if (this.requiredImages.length > 0 && this.step < this.requiredImages.length) {
                    this.step++;
                }

                if (this.requiredImages.length > 0 && this.step === this.requiredImages.length) {
                    console.log('Extra Images')
                }
            } else {
                this.imgPreview = null
            }
        },
        ImgComplete(id) {
            const find = this.images.find(el => el.id === id);
            return !!find;
        },
        UploadImage(obj) {
            this.isUploading = true;
            const uplUrl = this.$apiUrl + "/v2/checklist/job/photos/upload";
            const blob = this.b64toBlob(obj.image);
            const formData = new FormData;
            formData.append('file', blob);
            formData.append('folderId', this.folderId);
            formData.append('name', obj.name);
            formData.append('type', obj.type);
            formData.append('jobid', this.jobid);
            this.$axios.post(uplUrl, formData).then(resp => {
                const indx = this.images.findIndex(el => el.name === obj.name);
                if (indx > -1) {
                    this.images[indx].path = "https://drive.google.com/file/d/" + resp.data.data.id + "/view?usp=drivesdk"
                }
                this.isUploading = false;
            }).catch(err => {
                console.log(err)
                this.isUploading = false;
            })
        },
        onBackKeyDown(e) {
            console.log('bb pressed')
            e.preventDefault()
            e.stopImmediatePropagation();
            e.stopPropagation();
            return false;
        },
        beforeDestroy() {
            useMainStore().setCameraOpen(false)
        },
        b64toBlob(b64Data, contentType = '', sliceSize = 512) {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            return new Blob(byteArrays, {
                type: contentType
            });
        },
        presentAlertPrompt() {
            return new Promise((resolve, reject) => {
                alertController.create({
                    header: 'Enter a Filename!',
                    inputs: [
                        {
                            name: 'filename',
                            id: 'filename-id',
                            value: '',
                            placeholder: 'Filename...',
                        },
                    ],
                    buttons: [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: (e) => {
                                reject(e)
                            },
                        },
                        {
                            text: 'Ok',
                            handler: (e) => {
                                resolve(e)
                            },
                        },
                    ],
                }).then(alert => {
                    alert.present()
                });
            });
        },
    },
    watch: {
        currentZoom(val) {
            CameraPreview.setZoom(val);
        }
    }
})
</script>

<style lang="scss">
.camera-view {
    --background: transparent;
}
.carpart {
    flex: 0 0 10rem;
    color: #333;
    @apply border rounded h-20 w-40 px-3 py-2 text-center flex items-center justify-center text-xs relative;

    &.active {
        border-color: blue;
    }
}

.vue-slider.zoom-slider {
    position: absolute;
    right: 20px;
    top: 50%;
    bottom: 0;
}
</style>
