<template>
    <div>
        <label class="invisible" v-if="hiddenLabel">Label</label>
        <ion-datetime class="border border-color rounded py-2" display-format="DD-MM-YYYY" max="2030" :value="modelValue" @ion-change="$emit('update:modelValue', $event.target.value)" :placeholder="placeholder" presentation="date"></ion-datetime>
        <div class="font-bold text-red-500 mt-1">{{message}}</div>
    </div>
</template>

<script>
import {
    IonDatetime
} from '@ionic/vue';
export default {
    props: {
        modelValue: {},
        placeholder: {
            type: String,
            default: ""
        },
        hiddenLabel: {
            type: Boolean,
            default: false
        },
        v: Object
    },
    components: {
        IonDatetime
    },
    mounted() {
    },
    computed: {
        message() {
            if (this.v && this.v.$error) {
                for (let key in this.$options.messages) {
                    if (this.v[key].$invalid === true) {
                        return this.$options.messages[key](this.v)
                    }
                }
                return null;
            } else {
                return null
            }
        }
    },
    messages: {
        required: v => 'This field is required',
        email: v => 'Must be a valid e-mail',
        alphaNum: v => 'May only contain letters and numbers',
        minLength: v => 'Must be at least ' + v.$params.minLength.min + ' characters',
        goodPassword: v => '', //The password rules are always displayed, so no need to show a message
        sameAs: v => 'Must be same as ' + v.$params.sameAs.eq
    }
}
</script>

<style>
input.native-input {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
</style>
