import { defineStore } from 'pinia'
export const useMainStore = defineStore('mainStore',{
    state: () => {
        return {
            isAuthed: false,
            authUser: null,
            cameraOpen: false,
        }
    },
    actions: {
        setCameraOpen(payload) {
            this.cameraOpen = payload;
        },
        setIsAuthed(payload) {
            this.isAuthed = payload;
        },
        setAuthUser(payload) {
            this.authUser = payload;
        },
    },
    persist: true,
});
