<template>
    <div>
        <label class="invisible" v-if="hiddenLabel">Label</label>
        <div class="border rounded flex items-center border-color" :class="{'pl-5': prefix !== '', 'pr-5': suffix !== ''}">
            {{prefix}}
            <ion-input :type="type" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" :readonly="readonly" :placeholder="placeholder"></ion-input>
            {{suffix}}
        </div>
        <div class="font-bold text-red-500 mt-1">{{message}}</div>
    </div>
</template>

<script>
import {
    IonInput
} from '@ionic/vue';
export default {
    props: {
        prefix: {
            type: String,
            default: ""
        },
        suffix: {
            type: String,
            default: ""
        },
        modelValue: {},
        readonly: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: "text"
        },
        placeholder: {
            type: String,
            default: ""
        },
        hiddenLabel: {
            type: Boolean,
            default: false
        },
        v: Object
    },
    components: {
        IonInput
    },
    mounted() {
    },
    computed: {
        message() {
            if (this.v && this.v.$error) {
                for (let key in this.$options.messages) {
                    if(this.v[key] !== undefined) {
                        if (this.v[key].$invalid === true) {
                            return this.$options.messages[key](this.v)
                        }
                    }
                }
                return null;
            } else {
                return null
            }
        }
    },
    messages: {
        required: v => 'This field is required',
        email: v => 'Must be a valid e-mail',
        alphaNum: v => 'May only contain letters and numbers',
        numeric: v => 'May only contain numbers',
        minLength: (v) => 'Must be at least ' + v.$params.minLength.min + ' characters',
        goodPassword: v => '', //The password rules are always displayed, so no need to show a message
        sameAs: (v) => 'Must be same as ' + v.$params.sameAs.eq
    }
}
</script>

<style>
input.native-input {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
</style>
