<template>
    <ion-page>
        <ion-header :translucent="false">
            <ion-toolbar>
                <ion-title class="font-bold">Reception</ion-title>
                <span slot="end" class="pr-5">
                    <user-chip></user-chip>
                </span>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <ion-header collapse="condense">
                <ion-toolbar>
                    <ion-title size="large">Blank</ion-title>
                </ion-toolbar>
            </ion-header>

            <div id="container">
                <camera-orders v-if="cameraOpen" @cameraClosed="CameraClosed"></camera-orders>
                <div class="h-full" id="top" v-show="!cameraOpen">
                    <search placeholder="Search Customers..." :options="customerSearchOptions" @selected="PopulateCustomer" ref="customerSearch">
                        <template #option="{data}">{{data.label}}</template>
                    </search>

                    <q-card class="shadow-none border border-gray-300">
                        <template #header>
                            <div class="text-2xl font-bold">Customer</div>
                        </template>
                        <template #main class="q-pt-none">
                            <div class="grid grid-cols-2 gap-3">
                                <div class="">
                                    <label>Firstname</label>
                                    <q-input v-model="customer.firstname"></q-input>
                                </div>
                                <div class="">
                                    <label>Lastname</label>
                                    <q-input v-model="customer.lastname"></q-input>
                                </div>
                                <div class="">
                                    <label>Email</label>
                                    <q-input v-model="customer.email"></q-input>
                                </div>
                                <div class="">
                                    <label>Phone</label>
                                    <q-input v-model="customer.phone"></q-input>
                                </div>
                            </div>

                            <div class="mt-4" v-if="customer.bc_id">
                                <label>Address</label>
                                <select class="w-full bg-white py-2 border border-color rounded focus:outline-none" @change="UpdateAddress">
                                    <option disabled selected>Select Address...</option>
                                    <option v-for="addr in addressOptions" :key="addr.id" :value="addr.id">
                                        {{addr.address1}} - {{addr.city}} - {{addr.postal_code}}
                                    </option>
                                </select>
                            </div>

                            <div class="grid grid-cols-2 gap-3 mt-4">
                                <div class="">
                                    <label>Address 1</label>
                                    <q-input v-model="customer.address.address1"></q-input>
                                </div>
                                <div class="">
                                    <label>City</label>
                                    <q-input v-model="customer.address.city"></q-input>
                                </div>
                                <div class="">
                                    <label>County</label>
                                    <q-input v-model="customer.address.state_or_province"></q-input>
                                </div>
                                <div class="">
                                    <label>Postcode</label>
                                    <q-input v-model="customer.address.postal_code"></q-input>
                                </div>
                            </div>
                        </template>
                    </q-card>

                    <search placeholder="Search Parts..." :options="partsSearchOptions" @selected="AddPart" ref="partSearch">
                        <template #option="{data}">
                            <span class="bg-blue-500 text-white rounded px-3 py-1 mr-3">{{data.ItemNumber}}</span> {{data.ItemTitle}}
                        </template>
                    </search>

                    <q-card class="mt-2 shadow-none border border-gray-300">
                        <template #header>
                            <div class="text-2xl font-bold">Parts</div>
                        </template>
                        <template #main class="q-pt-none">
                            <div v-if="addedParts.length == 0" class="text-center text-lg opacity-50">
                                No Parts Added
                            </div>
                            <div v-else>
                                <ul>
                                    <li v-for="(part, index) in addedParts" :key="part.StockItemId" class="addedPart w-full h-full py-2" :class="{'border-b border-color': addedParts.length > 1 && index + 1 !== addedParts.length }" v-touch:drag.once="SwipedHandler(part)" v-touch:release="ResetListItem">
                                        <div class="flex items-center">
                                            <!--<img v-if="part.Images.length > 0" :src="part.Images[0].Source" class="w-12 mr-4">
                                            <img v-else src="https://via.placeholder.com/100" class="w-12 mr-4">-->
                                            <span class="bg-blue-500 text-white rounded px-3 py-1 mr-3">{{part.ItemNumber}}</span>
                                            <span class="text-lg whitespace-nowrap overflow-hidden overflow-ellipsis pr-4">{{part.ItemTitle}}</span>
                                            <number-input class="ml-auto numberinput" v-model="part.Quantity"></number-input>
                                            <!--<span class="ml-auto font-bold text-lg"><q-btn><i class="ic ic-camera"></i></q-btn></span>-->
                                        </div>
                                        <div class="flex items-center gap-10 mt-2 pl-0">
                                            <span><b>Available:</b> {{GetAvailableStock(part)}}</span>
                                            <span><b>In Orders:</b> {{GetInOrdersStock(part)}}</span>
                                            <span><b>Price:</b> £{{part.RetailPrice * (part.Quantity || 1)}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </q-card>

                    <div class="mt-3 px-3">
                        <q-btn class="w-full" :disabled="addedParts.length == 0" @click="OpenCamera">Take Images <span v-if="images.length > 0" class="ml-3 bg-white rounded-full h-5 w-5 text-center flex items-center text-black">{{images.length}}</span></q-btn>
                    </div>

                    <div class="flex px-3">
                        <h2 class="text-xl font-bold">Total: £{{partsTotal}}</h2>
                        <h2 class="text-xl font-bold ml-auto">Total (Inc VAT): £{{partsTotal * 1.2}}</h2>
                    </div>

                    <div class="pt-10 px-3">
                        <div class="w-full flex gap-4">
                            <q-btn class="w-full mt-4" color="#4d34c7" :disabled="addedParts.length == 0 || submittingToLW" @click="SubmitToBC">
                                <template v-if="!submittingToBC">Submit to BC</template>
                                <template v-else>
                                    <spinner color="#fff" track-color="#fff" :track-opacity="0.3"></spinner>
                                </template>
                            </q-btn>
                            <q-btn class="w-full mt-4" color="#212b36" :disabled="addedParts.length == 0 || submittingToBC" @click="SubmitToLW">
                                <template v-if="!submittingToLW">Submit to LW</template>
                                <template v-else>
                                    <spinner color="#fff" track-color="#fff" :track-opacity="0.3"></spinner>
                                </template>
                            </q-btn>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    loadingController,
    alertController,
} from '@ionic/vue';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import {
    defineComponent
} from 'vue';
import cameraOrders from '../../components/CameraOrders.vue'
import moment from 'moment';
import {useMainStore} from "@/store/mainStore";
export default defineComponent({
    name: 'Reception',
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
        cameraOrders
    },
    data() {
        return {
            cameraOpen: false,
            loading: null,
            customer: {
                id: null,
                bc_id: null,
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                address: {
                    address1: "",
                    address2: "",
                    address_type: "",
                    city: "",
                    company: "",
                    country: "",
                    country_code: "",
                    postal_code: "",
                    state_or_province: "",
                }
            },
            addressOptions: [],
            addedParts: [],
            parts: [],
            images: [],
            submittingToBC: false,
            submittingToLW: false,
            customerSearchOptions: {
                url: "/api/reception/search/customers"
            },
            partsSearchOptions: {
                url: "/api/reception/search/parts"
            },
            pickingOptions: {
                name: "quantity",
                options: [],
            },
        }
    },
    created() {},
    computed: {
        partsTotal() {
            var cost = 0;
            this.addedParts.forEach(part => {
                cost = (part.RetailPrice * part.Quantity) + cost;
            });
            return cost;
        }
    },
    mounted() {},
    methods: {
        PopulateCustomer(customer) {
            if (customer.BC_ID) {
                this.$axios.post(this.$apiUrl + '/api/reception/search/customers/address', {
                    bc_id: customer.BC_ID
                }).then(resp => {
                    console.log(resp.data)
                    this.addressOptions = resp.data;
                }).catch(err => {
                    console.log(err)
                })
            }
            this.customer.id = customer.id;
            this.customer.firstname = customer.firstname;
            this.customer.lastname = customer.lastname;
            this.customer.email = customer.email;
            this.customer.phone = customer.phonenumber;
            this.customer.bc_id = customer.BC_ID;
            this.$refs.customerSearch.ResetSearch()
        },
        UpdateAddress(e) {
            var id = e.target.selectedOptions[0].value;
            var find = this.addressOptions.find(el => el.id == id);
            if (find) {
                this.customer.address.address1 = find.address1
                this.customer.address.address2 = find.address2
                this.customer.address.address_type = find.address_type
                this.customer.address.city = find.city
                this.customer.address.company = find.company
                this.customer.address.country = find.country
                this.customer.address.country_code = find.country_code
                this.customer.address.postal_code = find.postal_code
                this.customer.address.state_or_province = find.state_or_province
            }
        },
        AddPart(part) {
            this.PresentAlertConfirm("Do you want to add " + part.ItemNumber + " to the order?").then(res => {
                part.Quantity = 1;
                this.addedParts.push(part)
                this.$refs.partSearch.ResetSearch()
            }).catch(cancel => {})
        },
        PresentAlertConfirm(msg) {
            return new Promise((resolve, reject) => {
                alertController.create({
                    cssClass: 'my-custom-class',
                    header: 'Confirm!',
                    message: msg,
                    buttons: [{
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                reject()
                            },
                        },
                        {
                            text: 'Okay',
                            handler: () => {
                                resolve();
                            },
                        },
                    ],
                }).then((alert) => {
                    alert.present();
                });
            });
        },
        PresentAlert(msg) {
            return new Promise((resolve, reject) => {
                alertController.create({
                    cssClass: 'my-custom-class',
                    header: 'Confirm!',
                    message: msg,
                    buttons: [{
                        text: 'Okay',
                        handler: () => {
                            resolve();
                        },
                    }, ],
                }).then((alert) => {
                    alert.present();
                });
            });
        },
        async PresentLoading() {
            this.loading = await loadingController.create({
                cssClass: 'my-custom-class',
                message: 'Please wait...'
            });
            await this.loading.present();
        },
        GetAvailableStock(part) {
            var find = part.StockLevels.find(el => el.Location.LocationName == "Default")
            if (find) {
                return find.Available
            } else {
                return 0;
            }
        },
        GetInOrdersStock(part) {
            var find = part.StockLevels.find(el => el.Location.LocationName == "Default")
            if (find) {
                return find.InOrders
            } else {
                return 0;
            }
        },
        SwipedHandler(part) {
            var self = this;
            return function (e) {
                var width = e.target.clientWidth;
                var clientX = e.touches[0].clientX;
                var perc = (clientX / width) * 100;
                var right = e.target.clientWidth - e.touches[0].clientX;
                e.target.style.right = right + "px"
                if (perc <= 50) {
                    console.log(perc)
                    var find = self.addedParts.findIndex(el => el.StockItemId === part.StockItemId)
                    if (find > -1) {
                        self.addedParts.splice(find, 1);
                    }
                }
            };
        },
        ResetListItem(e) {
            e.target.style.right = "0px";
        },
        OpenCamera() {
            this.cameraOpen = !this.cameraOpen
        },
        CameraClosed(images) {
            this.cameraOpen = false
            this.images.concat(images)
            for (let index = 0; index < images.length; index++) {
                const img = images[index];
                this.UploadImage("data:image/png;base64," + img.image, 'tester');
            }
        },
        async Submit() {
            //await this.PresentLoading();
            /*
            this.$axios.post(this.$apiUrl + '/api/reception/create/order', {
                customer: this.customer,
                parts: this.addedParts
            }).then(async (resp) => {
                console.log(resp.data)
                await this.loading.dismiss()
                this.loading = null
            }).catch(async err => {
                console.log(err)
                await this.loading.dismiss()
                this.loading = null
            })*/
        },
        async SubmitToBC() {
            await this.PresentLoading();
            this.submittingToBC = true;
            this.$axios.post(this.$apiUrl + '/api/reception/create/order/bc', {
                customer: this.customer,
                parts: this.addedParts,
                price: {
                    inc_vat: this.partsTotal * 1.2,
                    ex_vat: this.partsTotal
                }
            }).then(async (resp) => {
                this.submittingToBC = false;
                if (resp.data.success) {
                    for (let index = 0; index < this.images.length; index++) {
                        const img = this.images[index];
                        await this.UploadImage("data:image/png;base64," + img.image, resp.data.data.id).catch(err => {console.log(err)});
                    }
                    await this.loading.dismiss()
                    this.PresentAlert('Order Created In BC').then(() => {
                        this.ResetAll();
                    });
                } else {
                    await this.loading.dismiss()
                    this.PresentAlert('Failed To Create Order In BC');
                }
            }).catch(async err => {
                this.submittingToBC = false;
                await this.loading.dismiss()
                this.PresentAlert(err);
            })
        },
        async SubmitToLW() {
            await this.PresentLoading();
            this.submittingToLW = true;
            this.$axios.post(this.$apiUrl + '/api/reception/create/order/lw', {
                customer: this.customer,
                parts: this.addedParts,
                price: {
                    inc_vat: this.partsTotal * 1.2,
                    ex_vat: this.partsTotal
                }
            }).then(async (resp) => {
                this.submittingToLW = false;
                if (resp.data.id) {
                    for (let index = 0; index < this.images.length; index++) {
                        const img = this.images[index];
                        await this.UploadImage("data:image/png;base64," + img.image, resp.data.linnworks_order_number).catch(err => {console.log(err)});
                    }

                    await this.loading.dismiss()
                    this.PresentAlert('Order Created In LW').then(() => {
                        this.ResetAll();
                    });
                } else {
                    await this.loading.dismiss()
                    this.PresentAlert('Failed To Create Order In LW');
                }
            }).catch(async err => {
                this.submittingToLW = false;
                await this.loading.dismiss()
                this.PresentAlert(err);
            })
        },
        ResetAll() {
            this.cameraOpen = false;
            this.loading = null;
            this.customer = {
                    id: null,
                    bc_id: null,
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    address: {
                        address1: "",
                        address2: "",
                        address_type: "",
                        city: "",
                        company: "",
                        country: "",
                        country_code: "",
                        postal_code: "",
                        state_or_province: "",
                    }
                },
                this.addressOptions = [];
            this.addedParts = [];
            this.parts = [];
            this.images = [];
            this.submittingToBC = false;
            this.submittingToLW = false;
        },
        async UploadImage(image, orderNum) {
            return new Promise((resolve, reject) => {
                var imgOrderData = {
                    image: image,
                    mediaType: 'Picture',
                    type: "Orders",
                    orderNumber: orderNum,
                    reference: "Reception",
                    username: useMainStore().authUser.label
                }
                var imgUrl = 'http://192.168.2.88:6655/save-image';
                this.$axios.post(imgUrl, imgOrderData).then(async (resp) => {
                    if (resp.data.err !== null) {
                        await Filesystem.mkdir({
                            path: "orders/",
                            directory: Directory.Documents
                        }).catch(err => {
                            console.log(err)
                        });
                        var filename = imgOrderData.username.replace(' ', '')+"-"+imgOrderData.orderNumber + "-" + moment().format('x') + ".png";
                        const savedFile = await Filesystem.writeFile({
                            path: "orders/" + filename,
                            data: image,
                            directory: Directory.Documents
                        });
                        resolve();
                    }
                }).catch(async (err) => {
                    console.log(err)
                    reject();
                })
            });
        }
    }
})
</script>

<style scoped>
.addedPart {
    position: relative;
}

.addedPart>img,
.addedPart>span {
    pointer-events: none;
}

.anim-rotate {
    display: inline-block;
    animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
