<template>
    <ion-chip class="ion-margin-start" @click="Logout" v-if="mainStore && mainStore.isAuthed">
        <ion-avatar>
            <img :src="mainStore.authUser.img">
        </ion-avatar>
        <ion-label>{{mainStore.authUser.label}}</ion-label>
    </ion-chip>
</template>

<script>
import {
    IonAvatar,
    IonLabel,
    IonChip,
} from '@ionic/vue';
import {useMainStore} from "@/store/mainStore";
import router from "@/router";
export default {
    components: {
        IonChip,
        IonAvatar,
        IonLabel
    },
    data() {
        return {
            mainStore: useMainStore()
        }
    },
    methods: {
        Logout() {
            useMainStore().setIsAuthed(false);
            useMainStore().setAuthUser(null);
            //this.$store.commit('mainStore/setIsAuthed', false)
            //this.$store.commit('mainStore/setAuthUser', null)
            router.back();
        }
    }
}
</script>

<style>

</style>
