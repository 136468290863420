<template>
    <ion-page>
        <ion-header :translucent="false">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button></ion-back-button>
                </ion-buttons>
                <ion-title class="font-bold">Workshop Checklist</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <ion-header collapse="condense">
                <ion-toolbar>
                    <ion-title size="large">Blank</ion-title>
                </ion-toolbar>
            </ion-header>

            <div id="container">
                <camera v-if="cameraOpen" @cameraClosed="CameraClosed" :type="photoType" :current-images="currentImages" :folder-id="form.folderId" check-type="Workshop" :jobid="form.jobId" :required-images="requiredImages"></camera>
                <div v-show="!cameraOpen" class="min-h-screen h-full" id="top">
                    <q-card class="">
                        <template #header>
                            <div class="text-2xl font-bold">General</div>
                        </template>
                        <template #main class="q-pt-none">
                            <help-text>Road Test - BEFORE GOING THROUGH GATES, please make sure you use the laptop, follow logging guides for <a target="_blank" href="https://drive.google.com/open?id=0B-RqqvwYVL1FLTBESzF2eGlxUWM">VCDS</a><br><a target="_blank" href="https://drive.google.com/drive/u/0/folders/1d62aAUsGM5nZ-UIHxbeLV83WUqjYPxTZ">Preference Folder</a></help-text>

                            <field-section class="mt-10" title="Registration Number">
                                <q-input v-model="v$.form.reg.$model" :v="v$.form.reg" />
                            </field-section>

                            <field-section class="mt-10" title="Customer Name">
                                <q-input v-model="v$.form.name.$model" :v="v$.form.name" />
                            </field-section>

                            <field-section class="mt-10" title="Notes">
                                <q-editor v-model="v$.form.notes.$model" :v="v$.form.notes" min-height="5rem" />
                            </field-section>

                            <field-section class="mt-10" title="Start Full Fault Code Scan">
                                <button-radio :options="['Not Started', 'Started']" v-model="v$.form.fullscanStarted.$model" :v="v$.form.fullscanStarted"></button-radio>
                            </field-section>
                        </template>
                    </q-card>

                    <q-card class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">Fluid Levels</div>
                        </template>
                        <template #main class="q-pt-none">
                            <field-section class="mt-4" title="Engine Oil">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.engineOil.$model" :v="v$.form.engineOil"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.engineOilDesc.$model" :v="v$.form.engineOilDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Brake Fluid">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.brakeFluid.$model" :v="v$.form.brakeFluid"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.brakeFluidDesc.$model" :v="v$.form.brakeFluidDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Coolant">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.coolantTemp.$model" :v="v$.form.coolantTemp"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.coolantTempDesc.$model" :v="v$.form.coolantTempDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Power Steering">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.powerSteering.$model" :v="v$.form.powerSteering"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.powerSteeringDesc.$model" :v="v$.form.powerSteeringDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Screenwash">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.screenwash.$model" :v="v$.form.screenwash"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.screenwashDesc.$model" :v="v$.form.screenwashDesc" />
                                </div>
                            </field-section>
                        </template>
                    </q-card>

                    <q-card class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">Signals / Visibility</div>
                        </template>
                        <template #main class="q-pt-none">
                            <field-section class="mt-4" title="Indicators / Hazard">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.indicatorsHazard.$model" :v="v$.form.indicatorsHazard"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.indicatorsHazardDesc.$model" :v="v$.form.indicatorsHazardDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Dipped & Main beam">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.dippedMainBeam.$model" :v="v$.form.dippedMainBeam"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.dippedMainBeamDesc.$model" :v="v$.form.dippedMainBeamDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Sidelights">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.sidelights.$model" :v="v$.form.sidelights"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.sidelightsDesc.$model" :v="v$.form.sidelightsDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Front Fog">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.frontFog.$model" :v="v$.form.frontFog"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.frontFogDesc.$model" :v="v$.form.frontFogDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Rear Fog">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.rearFog.$model" :v="v$.form.rearFog"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.rearFogDesc.$model" :v="v$.form.rearFogDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Number plate light and condition">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.numberPlateLightAndCondition.$model" :v="v$.form.numberPlateLightAndCondition"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.numberPlateLightAndConditionDesc.$model" :v="v$.form.numberPlateLightAndConditionDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Brake">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.brake.$model" :v="v$.form.brake"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.brakeDesc.$model" :v="v$.form.brakeDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Reverse">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.reverse.$model" :v="v$.form.reverse"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.reverseDesc.$model" :v="v$.form.reverseDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Wiper Blades & Wash Wipe">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.wiperBladesWashWipe.$model" :v="v$.form.wiperBladesWashWipe"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.wiperBladesWashWipeDesc.$model" :v="v$.form.wiperBladesWashWipeDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Windscreen">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.windscreen.$model" :v="v$.form.windscreen"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.windscreenDesc.$model" :v="v$.form.windscreenDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Mirrors inc Rear View">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.mirrorsIncRearView.$model" :v="v$.form.mirrorsIncRearView"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.mirrorsIncRearViewDesc.$model" :v="v$.form.mirrorsIncRearViewDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Horn">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.horn.$model" :v="v$.form.horn"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.hornDesc.$model" :v="v$.form.hornDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Front Windows Open">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.frontWindowsOpen.$model" :v="v$.form.frontWindowsOpen"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.frontWindowsOpenDesc.$model" :v="v$.form.frontWindowsOpenDesc" />
                                </div>
                            </field-section>
                        </template>
                    </q-card>

                    <q-card class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">Safety</div>
                        </template>
                        <template #main class="q-pt-none">
                            <field-section class="mt-4" title="Structure and integrity of Chassis">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.structureAndIntegrityOfChassis.$model" :v="v$.form.structureAndIntegrityOfChassis"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.structureAndIntegrityOfChassisDesc.$model" :v="v$.form.structureAndIntegrityOfChassisDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Front Shock Absorbers">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.frontShockAbsorbers.$model" :v="v$.form.frontShockAbsorbers"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.frontShockAbsorbersDesc.$model" :v="v$.form.frontShockAbsorbersDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Front Shocker Tops">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.frontShockerTops.$model" :v="v$.form.frontShockerTops"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.frontShockerTopsDesc.$model" :v="v$.form.frontShockerTopsDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Rear Shock Absorbers">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.rearShockAbsorbers.$model" :v="v$.form.rearShockAbsorbers"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.rearShockAbsorbersDesc.$model" :v="v$.form.rearShockAbsorbersDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Rear Shocker Tops">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.rearShockerTops.$model" :v="v$.form.rearShockerTops"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.rearShockerTopsDesc.$model" :v="v$.form.rearShockerTopsDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Front Suspension Bushes">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.frontSuspensionBushes.$model" :v="v$.form.frontSuspensionBushes"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.frontSuspensionBushesDesc.$model" :v="v$.form.frontSuspensionBushesDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Rear Suspension bushes">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.rearSuspensionBushes.$model" :v="v$.form.rearSuspensionBushes"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.rearSuspensionBushesDesc.$model" :v="v$.form.rearSuspensionBushesDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Steering Rack and Track Rod Ends">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.steeringRackAndTrackRodEnds.$model" :v="v$.form.steeringRackAndTrackRodEnds"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.steeringRackAndTrackRodEndsDesc.$model" :v="v$.form.steeringRackAndTrackRodEndsDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="CV Boots and Driveshafts">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.cvBootsAndDriveshafts.$model" :v="v$.form.cvBootsAndDriveshafts"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.cvBootsAndDriveshaftsDesc.$model" :v="v$.form.cvBootsAndDriveshaftsDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Wheel Bearings">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.wheelBearings.$model" :v="v$.form.wheelBearings"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.wheelBearingsDesc.$model" :v="v$.form.wheelBearingsDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Engine Mounts">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.engineMounts.$model" :v="v$.form.engineMounts"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.engineMountsDesc.$model" :v="v$.form.engineMountsDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Exhaust (Leaks / Mountings)">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.exhaustLeaksMountings.$model" :v="v$.form.exhaustLeaksMountings"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.exhaustLeaksMountingsDesc.$model" :v="v$.form.exhaustLeaksMountingsDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Front Brake Discs & Pads">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.frontBrakeDiscsPads.$model" :v="v$.form.frontBrakeDiscsPads"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.frontBrakeDiscsPadsDesc.$model" :v="v$.form.frontBrakeDiscsPadsDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Rear Brake Discs & Pads">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.rearBrakeDiscsPads.$model" :v="v$.form.rearBrakeDiscsPads"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.rearBrakeDiscsPadsDesc.$model" :v="v$.form.rearBrakeDiscsPadsDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Front Brake Hoses & Pipes">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.frontBrakeHosesPipes.$model" :v="v$.form.frontBrakeHosesPipes"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.frontBrakeHosesPipesDesc.$model" :v="v$.form.frontBrakeHosesPipesDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Rear Brake Hoses & Pipes">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.rearBrakeHosesPipes.$model" :v="v$.form.rearBrakeHosesPipes"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.rearBrakeHosesPipesDesc.$model" :v="v$.form.rearBrakeHosesPipesDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Handbrake">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.handbrake.$model" :v="v$.form.handbrake"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.handbrakeDesc.$model" :v="v$.form.handbrakeDesc" />
                                </div>
                            </field-section>
                        </template>
                    </q-card>

                    <q-card class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">TYRE PRESSURE & TREAD DEPTH</div>
                        </template>
                        <template #main class="q-pt-none">
                            <field-section class="mt-4" title="Front Right">
                                <div class="flex gap-2">
                                    <tread-depth label="Tread Depth" size="xs" v-model="v$.form.frontRightTyre.$model" />
                                    <psi-input label="Current PSI" class="ml-2 w-28" size="xs" v-model="v$.form.frontRightTyrePsi.$model" />
                                    <psi-input label="Correct PSI" class="ml-2 w-28" size="xs" v-model="v$.form.frontRightTyreCorrectPsi.$model" />
                                    <q-input hidden-label v-model="v$.form.frontRightTyreDesc.$model" size="xs" shadow="sm" textsize="md" bold class="ml-3"></q-input>
                                    <div>
                                        <label class="invisible mb-1 block">Label</label>
                                        <ion-checkbox style="height:33px; width:33px" v-model="v$.form.frontRightTyrePsiCorrected.$model"></ion-checkbox>
                                    </div>
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Front Left">
                                <div class="flex gap-2">
                                    <tread-depth label="Tread Depth" size="xs" v-model="v$.form.frontLeftTyre.$model" />
                                    <psi-input label="Current PSI" class="ml-2 w-28" size="xs" v-model="v$.form.frontLeftTyrePsi.$model" />
                                    <psi-input label="Correct PSI" class="ml-2 w-28" size="xs" v-model="v$.form.frontLeftTyreCorrectPsi.$model" />
                                    <q-input hidden-label v-model="v$.form.frontLeftTyreDesc.$model" size="xs" shadow="sm" textsize="md" bold class="ml-3"></q-input>
                                    <div>
                                        <label class="invisible mb-1 block">Label</label>
                                        <ion-checkbox style="height:33px; width:33px" v-model="v$.form.frontLeftTyrePsiCorrected.$model"></ion-checkbox>
                                    </div>
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Rear Left">
                                <div class="flex gap-2">
                                    <tread-depth label="Tread Depth" size="xs" v-model="v$.form.rearLeftTyre.$model" />
                                    <psi-input label="Current PSI" class="ml-2 w-28" size="xs" v-model="v$.form.rearLeftTyrePsi.$model" />
                                    <psi-input label="Correct PSI" class="ml-2 w-28" size="xs" v-model="v$.form.rearLeftTyreCorrectPsi.$model" />
                                    <q-input hidden-label v-model="v$.form.rearLeftTyreDesc.$model" size="xs" shadow="sm" textsize="md" bold class="ml-3"></q-input>
                                    <div>
                                        <label class="invisible mb-1 block">Label</label>
                                        <ion-checkbox style="height:33px; width:33px" v-model="v$.form.rearLeftTyrePsiCorrected.$model"></ion-checkbox>
                                    </div>
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Rear Right">
                                <div class="flex gap-2">
                                    <tread-depth label="Tread Depth" size="xs" v-model="v$.form.rearRightTyre.$model" />
                                    <psi-input label="Current PSI" class="ml-2 w-28" size="xs" v-model="v$.form.rearRightTyrePsi.$model" />
                                    <psi-input label="Correct PSI" class="ml-2 w-28" size="xs" v-model="v$.form.rearRightTyreCorrectPsi.$model" />
                                    <q-input hidden-label v-model="v$.form.rearRightTyreDesc.$model" size="xs" shadow="sm" textsize="md" bold class="ml-3"></q-input>
                                    <div>
                                        <label class="invisible mb-1 block">Label</label>
                                        <ion-checkbox style="height:33px; width:33px" v-model="v$.form.rearRightTyrePsiCorrected.$model"></ion-checkbox>
                                    </div>
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Spare">
                                <div class="flex gap-2">
                                    <tread-depth label="Tread Depth" size="xs" v-model="v$.form.spareTyre.$model" />
                                    <psi-input label="Current PSI" class="ml-2 w-28" size="xs" v-model="v$.form.spareTyrePsi.$model" />
                                    <psi-input label="Correct PSI" class="ml-2 w-28" size="xs" v-model="v$.form.spareTyreCorrectPsi.$model" />
                                    <q-input hidden-label v-model="v$.form.spareTyreDesc.$model" size="xs" shadow="sm" textsize="md" bold class="ml-3"></q-input>
                                    <div>
                                        <label class="invisible mb-1 block">Label</label>
                                        <ion-checkbox style="height:33px; width:33px" v-model="v$.form.spareTyrePsiCorrected.$model"></ion-checkbox>
                                    </div>
                                </div>
                            </field-section>
                        </template>
                    </q-card>

                    <q-card class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">Operation</div>
                        </template>
                        <template #main class="q-pt-none">
                            <field-section class="mt-4" title="Condition and Mounting of Bumpers">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.conditionAndMountingOfBumpers.$model" :v="v$.form.conditionAndMountingOfBumpers"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.conditionAndMountingOfBumpersDesc.$model" :v="v$.form.conditionAndMountingOfBumpersDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Condition of Lights">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.conditionOfLights.$model" :v="v$.form.conditionOfLights"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.conditionOfLightsDesc.$model" :v="v$.form.conditionOfLightsDesc" />
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Door Handles and Locks">
                                <div class="flex gap-3 w-full">
                                    <colour-select :options="basicColourOpts" padding v-model="v$.form.doorHandlesAndLocks.$model" :v="v$.form.doorHandlesAndLocks"></colour-select>
                                    <q-input class="w-full" v-model="v$.form.doorHandlesAndLocksDesc.$model" :v="v$.form.doorHandlesAndLocksDesc" />
                                </div>
                            </field-section>

                            <div id="photos"></div>
                            <field-section class="mt-10" title="Photos of any Significant Issues">
                                <!--<q-btn color="primary" icon="camera_alt" @click="OpenCamera('exterior')">Take Pictures <span class="ml-3 bg-white h-5 w-5 text-gray-900 flex items-center justify-center rounded-full text-xs" v-if="form.photos.length > 0">{{form.photos.length}}</span></q-btn>-->
                                <!--<PhotoCapture :img="{}" @image-taken="imageTaken" type="Workshop"></PhotoCapture>-->

                                <multi-image-capture ref="imageCapture" type="Workshop"/>
                            </field-section>

                            <div class="bg-gray-100 p-2 rounded mt-10">
                                THE INSPECTIONS CARRIED OUT ONLY RELATE TO ITEMS THAT CAN BE SEEN ON THE VEHICLE WHERE NO DISMANTLING IS REQUIRED. AS A RESULT, WE CANNOT BE HELD RESPONSIBLE FOR NOT DETECTING ANY NON-VISIBLE FAULT
                            </div>

                            <field-section class="mt-10" title="Full System Fault Code Scan">
                                <div class="flex gap-3 w-full items-center">
                                    <button-check :options="['Complete - Saved to Google drive']" v-model="v$.form.faultScanComplete.$model" :v="v$.form.faultScanComplete"></button-check>
                                </div>
                            </field-section>

                            <help-text class="mt-10">
                                Once work commences move the task list from one specialist to the other specialist as work progresses - MOVE TO “URGENT” IF UNSURE AND ADD NOTES / Notify management.<br/>
                                When all work is finished Tasks are moved to Office staff who will notify customer
                            </help-text>

                            <field-section class="mt-10" title="Checklist Notes">
                                <div class="border border-color p-2 rounded">
                                    <ul id="generatedNotes">
                                        <li v-for="(note,index) in generatedNotes" :key="index">{{ConvertNote(note)}}</li>
                                    </ul>
                                </div>
                            </field-section>
                        </template>
                    </q-card>


                    <div class="pt-3 pb-3 px-2 w-full">
                        <q-btn expand="block" @click="Submit">SUBMIT</q-btn>
                    </div>

                    <q-card v-if="errors.length > 0">
                        <template #header>
                            <div class="text-2xl font-bold">Errors</div>
                        </template>
                        <template #main class="q-pt-none">
                            <div>
                                <ul>
                                    <li v-for="(err, index) in errors" :key="index">
                                        <span class="inline-block mb-2 font-bold text-red-500">{{err.field}} - </span> <span class="inline-block w-32">{{err.msg}}</span>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </q-card>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCheckbox,
    IonButtons,
    IonBackButton,
    loadingController,
    alertController
} from '@ionic/vue';
import {
    defineComponent
} from 'vue';
import useVuelidate from '@vuelidate/core'
import {
    required,
    requiredIf,
    email,
    minValue,
    numeric
} from '@vuelidate/validators'
import camera from '../../components/Camera.vue'
import {useMainStore} from "@/store/mainStore";
import {useWorkshopStore} from "@/store/workshopStore";
import MultiImageCapture from "@/components/MultiImageCapture.vue";
export default defineComponent({
    name: 'Home',
    components: {
        MultiImageCapture,
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
        IonCheckbox,
        IonButtons,
        IonBackButton,
        camera
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            layout: "blocked",
            photoType: null,
            currentImages: [],
            loading: null,
            cameraOpen: false,
            jobs: [],
            jobIdOptions: [],
            selectedJob: null,
            jobLoaded: false,
            staff: [],
            generatedNotes: [],
            jobslist: [],
            dashlights: [],
            requiredImages: [{id: 1, text: "Extra"}],
            basicColourOpts: [{
                color: 'Red',
                value: 'Urgent'
            }, {
                color: 'Orange',
                value: 'Recommended'
            }, {
                color: 'Green',
                value: 'Visually OK'
            }],
            form: useWorkshopStore(),
            errors: []
        }
    },
    validations() {
        return {
            form: {
                reg: {
                    required
                },
                name: {
                    required
                },
                checkCompletedBy: {
                    required
                },
                bookingFormID: {
                    required
                },
                notes: {
                    required
                },
                fullscanStarted: {required},

                engineOil: {required},
                engineOilDesc: {},
                brakeFluid: {required},
                brakeFluidDesc: {},
                coolantTemp: {required},
                coolantTempDesc: {},
                powerSteering: {required},
                powerSteeringDesc: {},
                screenwash: {required},
                screenwashDesc: {},

                indicatorsHazard: {required},
                indicatorsHazardDesc: {},
                dippedMainBeam: {required},
                dippedMainBeamDesc: {},
                sidelights: {required},
                sidelightsDesc: {},
                frontFog: {required},
                frontFogDesc: {},
                rearFog: {required},
                rearFogDesc: {},
                numberPlateLightAndCondition: {required},
                numberPlateLightAndConditionDesc: {},
                brake: {required},
                brakeDesc: {},
                reverse: {required},
                reverseDesc: {},
                wiperBladesWashWipe: {required},
                wiperBladesWashWipeDesc: {},
                windscreen: {required},
                windscreenDesc: {},
                mirrorsIncRearView: {required},
                mirrorsIncRearViewDesc: {},
                horn: {required},
                hornDesc: {},
                frontWindowsOpen: {required},
                frontWindowsOpenDesc: {},

                frontRightTyre: {required},
                frontLeftTyre: {required},
                rearLeftTyre: {required},
                rearRightTyre: {required},
                spareTyre: {required},
                frontRightTyrePsi: {required},
                frontRightTyrePsiCorrected: {required},
                frontRightTyreCorrectPsi: {required},
                frontLeftTyrePsi: {required},
                frontLeftTyrePsiCorrected: {required},
                frontLeftTyreCorrectPsi: {required},
                rearRightTyrePsi: {required},
                rearRightTyrePsiCorrected: {required},
                rearRightTyreCorrectPsi: {required},
                rearLeftTyrePsi: {required},
                rearLeftTyrePsiCorrected: {required},
                rearLeftTyreCorrectPsi: {required},
                spareTyrePsi: {required},
                spareTyrePsiCorrected: {required},
                spareTyreCorrectPsi: {required},
                frontRightTyreDesc: {},
                frontLeftTyreDesc: {},
                rearRightTyreDesc: {},
                rearLeftTyreDesc: {},
                spareTyreDesc: {},

                structureAndIntegrityOfChassis: {required},
                structureAndIntegrityOfChassisDesc: {},
                frontShockAbsorbers: {required},
                frontShockAbsorbersDesc: {},
                frontShockerTops: {required},
                frontShockerTopsDesc: {},
                rearShockAbsorbers: {required},
                rearShockAbsorbersDesc: {},
                rearShockerTops: {required},
                rearShockerTopsDesc: {},
                frontSuspensionBushes: {required},
                frontSuspensionBushesDesc: {},
                rearSuspensionBushes: {required},
                rearSuspensionBushesDesc: {},
                steeringRackAndTrackRodEnds: {required},
                steeringRackAndTrackRodEndsDesc: {},
                cvBootsAndDriveshafts: {required},
                cvBootsAndDriveshaftsDesc: {},
                wheelBearings: {required},
                wheelBearingsDesc: {},
                engineMounts: {required},
                engineMountsDesc: {},
                exhaustLeaksMountings: {required},
                exhaustLeaksMountingsDesc: {},
                frontBrakeDiscsPads: {required},
                frontBrakeDiscsPadsDesc: {},
                rearBrakeDiscsPads: {required},
                rearBrakeDiscsPadsDesc: {},
                frontBrakeHosesPipes: {required},
                frontBrakeHosesPipesDesc: {},
                rearBrakeHosesPipes: {required},
                rearBrakeHosesPipesDesc: {},
                handbrake: {required},
                handbrakeDesc: {},
                conditionAndMountingOfBumpers: {required},
                conditionAndMountingOfBumpersDesc: {},
                conditionOfLights: {required},
                conditionOfLightsDesc: {},
                doorHandlesAndLocks: {required},
                doorHandlesAndLocksDesc: {},
                faultScanComplete: {required}
            }
        }
    },
    async mounted() {
        await this.presentLoading();
        this.form.checkCompletedBy = useMainStore().authUser.value
        this.form.jobId = this.$router.currentRoute.value.params.id
        this.GetStaff().then(() => {
            this.GetJobById(this.$router.currentRoute.value.params.id)
        })
        for (const k in this.form) {
            this.$watch('form.' + k, (val, oldVal) => {
                this.NoteUpdater(k, val, oldVal);
            })
        }
    },
    methods: {
        UpdateLayout(a) {
            var val = a.detail.checked;
            if (val) {
                this.layout = "condensed";
            } else {
                this.layout = "blocked";
            }
        },
        GetJobById(id) {
            const jobid = id;
            this.$axios.get(this.$apiUrl + '/v2/checklist/job/' + jobid).then(async (resp) => {
                await this.$axios.get(this.$apiUrl + '/v2/checklist/job/folder/' + jobid).then(response => {
                    if (response.data && response.data.success) {
                        this.form.folderId = response.data.message;
                    } else {
                        this.form.folderId = this.$checklistTempFolder;//'0B-RqqvwYVL1FYnA5VktBWjhiQlU';
                    }
                })
                this.Populate(resp.data);
                await this.loading.dismiss()
                this.loading = false;
            }).catch(async() => {
                await this.loading.dismiss()
                this.loading = false;
            })
        },
        GetJob(resp, e) {
            this.loading = true;
            const jobid = e.value;
            this.$axios.get(this.$apiUrl + '/v2/checklist/job/' + jobid).then(async (resp) => {
                this.$axios.get(this.$apiUrl + '/v2/checklist/job/folder/' + jobid).then(response => {
                    if (response.data && response.data.success) {
                        this.form.folderId = response.data.message;
                    } else {
                        this.form.folderId = this.$checklistTempFolder;//'0B-RqqvwYVL1FYnA5VktBWjhiQlU';
                    }
                }).catch(err => {
                    this.form.folderId = this.$checklistTempFolder;//'0B-RqqvwYVL1FYnA5VktBWjhiQlU';
                })
                this.Populate(resp.data);
                await this.loading.dismiss()
                this.loading = false;
            }).catch(async () => {
                await this.loading.dismiss()
                this.loading = false;
            })
        },
        Populate(job) {
            if (job.folder_id === null || job.folder_id === '') {
                alert('NO FOLDER FOR JOB');
            }
            this.jobLoaded = true;
            this.selectedJob = job;
            this.form.customerId = job.customer_id;
            this.form.bookingFormID = job.id.toString();
            this.form.name = job.customer.firstname + ' ' + job.customer.lastname;
            if (job.vehicle) {
                this.form.reg = job.vehicle.VRVrm || '';
            }
            this.form.notes = job.note || '';
            /*setTimeout(() => {
                this.$refs.notes.setContent(this.form.notes)
            }, 500);*/
        },
        GetStaff() {
            return new Promise((resolve, reject) => {
                this.$axios.get('https://api.darkside-developments.com/api/users/all').then((resp) => {
                    this.staff = resp.data;
                    this.staff.push({
                        "id": 42,
                        "username": "Workshop",
                        "nicename": "Workshop"
                    })
                    this.staff.push({
                        "id": 44,
                        "username": "Tuning",
                        "nicename": "Tuning"
                    })
                    this.staff.push({
                        "id": 99,
                        "username": "NA",
                        "nicename": "NA"
                    })
                    this.staff.sort(this.NicenameAlphabetical);
                    resolve()
                })
            });
        },
        NicenameAlphabetical(a, b) {
            const A = a.nicename.toUpperCase();
            const B = b.nicename.toUpperCase();

            let comparison = 0;
            if (A > B) {
                comparison = 1;
            } else if (A < B) {
                comparison = -1;
            }
            return comparison;
        },
        GetUsername(id) {
            if (id) {
                const user = this.staff.find(el => el.id === id);
                if (user === undefined) {
                    return ''
                }
                return user.nicename;
            } else {
                return '';
            }
        },
        NoteUpdater(name, val, old) {
            if (name.includes('desc')) {
                name = name.replace('_desc', '')
                val = this.form[name];
            }
            const fieldname = name;
            const field = this.warningFields;
            const warn = field[name];
            const title = name.replace(/_/g, ' ');
            name = title.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            if (Array.isArray(val)) {
                val = this.$_.join(val, ',');
                const checkboxes = document.querySelectorAll('[name="' + fieldname.toLowerCase() + '"]:checked');
                if (checkboxes.length > 0) {
                    val = Array.prototype.slice.call(checkboxes).map(x => x.value).join(',');
                }
            }
            if (warn) {
                switch (warn.warnType) {
                    case 'interval':
                        this.generatedNotes = this.NoteIntervalUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'values':
                        this.generatedNotes = this.NoteValuesUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'value':
                        this.generatedNotes = this.NoteValueUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'optnotes':
                        this.generatedNotes = this.NoteOptNotesUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'optvalues':
                        this.generatedNotes = this.NoteOptValuesUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'valmsg':
                        this.generatedNotes = this.NoteValMessageUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'notes':
                        console.log('has - notes')
                        break;
                    case 'optnotesfocus':
                        console.log('has - optnotesfocus')
                        break;
                    case 'psi':
                        console.log('has - psi')
                        break;
                    default:
                        break;
                }
            }
        },
        ConvertNote(str) {
            var splitStr = str.split(' ');
            var first = splitStr[0];
            var join = str.replace(first, '');
            return this.$_.upperFirst(this.$_.lowerCase(first)) + " " + join;
        },
        OpenCamera(type) {
            this.photoType = type;
            this.currentImages = this.form.photos
            this.cameraOpen = !this.cameraOpen
        },
        CameraClosed(images) {
            this.cameraOpen = false
            if (this.photoType === "exterior") {
                this.form.photos.concat(images)
            }
            if (this.photoType === "interior") {
                this.form.photos.concat(images)
            }
            this.photoType = null;
            setTimeout(() => {
                this.handleScroll();
            }, 500);
        },
        handleScroll() {
            let element = document.getElementById('photos');
            element.scrollIntoView({
                behavior: 'smooth'
            });
        },
        imageTaken(result) {
            this.form.photos.push(result)
        },
        async Submit() {
            this.v$.$touch()
            var errors = [];
            for (const [key, value] of Object.entries(this.v$.form)) {
                if (!key.includes("$")) {
                    if (value.$error) {
                        if (value.$invalid === true) {
                            for (let key2 in this.$options.messages) {
                                if (value[key2]) {
                                    var fieldName = this.$_.startCase(key)
                                    errors.push({
                                        field: fieldName,
                                        msg: value[key2].$message
                                    })
                                }
                            }
                        }
                    }
                }
            }
            if (errors.length > 0) {
                document.querySelector('ion-content').scrollToTop(500);
                this.errors = errors
            } else {
                await this.presentLoading();
                this.form.$state.photos = this.$refs.imageCapture.images;
                const form_data = new FormData();
                for ( let key in this.form.$state ) {
                    if(key === "photos") {
                        for ( let key2 in this.form.$state[key] ) {
                            for (const key3 in this.form.$state[key][key2]) {
                                form_data.append(key + "[" +key2+"]" + "["+key3+"]", this.form.$state[key][key2][key3]);
                            }
                        }
                    } else {
                        form_data.append(key, this.form.$state[key]);
                    }
                }

                form_data.append("generatedNotes", document.getElementById("generatedNotes").innerText.replaceAll('\n', '<br/>'));

                this.$axios.post(this.$apiUrl + '/v2/checklist/complete/workshop', form_data).then(async (resp) => {
                    await this.loading.dismiss()
                    this.loading = false
                    await this.presentAlert("Complete", "Form successfully submitted!", true)
                }).catch(async (err) => {
                    console.log(err)
                    await this.loading.dismiss()
                    this.loading = false
                    await this.presentAlert("Error", err, false)
                })
            }
        },
        async presentLoading() {
            this.loading = await loadingController.create({
                cssClass: 'my-custom-class',
                message: 'Please wait...'
            });
            await this.loading.present();
        },
        async presentAlert(header, msg, redirect = false) {
            const alert = await alertController.create({
                cssClass: 'my-custom-class',
                header: header,
                message: msg
            });
            await alert.present();

            const {
                role
            } = await alert.onDidDismiss();
            if (redirect) {
                this.$router.push('/checklists')
            }
            console.log('onDidDismiss resolved with role', role);
        },
    },
    messages: {
        required: v => 'This field is required',
        email: v => 'Must be a valid e-mail',
        alphaNum: v => 'May only contain letters and numbers',
        minLength: v => 'Must be at least ' + v.$params.minLength.min + ' characters',
        goodPassword: v => '', //The password rules are always displayed, so no need to show a message
        sameAs: v => 'Must be same as ' + v.$params.sameAs.eq
    }
});
</script>

<style lang="scss" scoped>
div.blocked {
    display: flex;

    >label {
        width: 40%;
    }

    >div {
        width: 60%
    }
}
</style>
