<template>
    <ion-select placeholder="Select One" class="border rounded border-color" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
        <ion-select-option v-for="opt in options" :key="opt" :value="opt">{{opt}}</ion-select-option>
    </ion-select>
    <div class="font-bold text-red-500 mt-1">{{message}}</div>
</template>

<script>
import {
    IonSelect,
    IonSelectOption
} from '@ionic/vue';
export default {
    props: ['modelValue', 'options', 'prefix', 'suffix', 'v'],
    components: {
        IonSelect,
        IonSelectOption
    },
    computed: {
        message() {
            if (this.v && this.v.$error) {
                for (let key in this.$options.messages) {
                    if (this.v[key].$invalid === true) {
                        return this.$options.messages[key](this.v)
                    }
                }
                return null;
            } else {
                return null
            }
        }
    },
    messages: {
        required: v => 'This field is required',
        email: v => 'Must be a valid e-mail',
        alphaNum: v => 'May only contain letters and numbers',
        minLength: v => 'Must be at least ' + v.$params.minLength.min + ' characters',
        goodPassword: v => '', //The password rules are always displayed, so no need to show a message
        sameAs: v => 'Must be same as ' + v.$params.sameAs.eq
    }
}
</script>

<style>

</style>
