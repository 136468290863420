<template>
<div>
    <label class='block tracking-wide text-gray-700 text-xs font-bold mb-1' v-if="label !== ''">{{label}}</label>
    <div class="flex flex-nowrap items-center">
        <input class="appearance-none block w-full text-gray-700 border border-gray-400 rounded-md leading-tight focus:outline-none focus:border-gray-500" type="number" :disabled="disabled" :readonly="readonly" step=0.1 :value="val" :placeholder="placeholder" @blur="OnBlur" @input="OnInput" @focus="OnFocus" @change="OnChange" :class="[classes, {
                    'font-bold': bold,
                    'bg-red-500 text-white': parseFloat(val) < 1.6,
                    'bg-yellow-500 text-white': parseFloat(val) >= 1.6 && parseFloat(val) < 3,
                    'bg-green-500 text-white': parseFloat(val) >= 3
                }]" :name="name" :min="min">
        <div v-if="showicon">
            <q-icon name="check_circle" class="text-green-600 text-3xl ml-3" v-if="val !== ''" />
            <q-icon name="cancel" class="text-red-600 text-3xl ml-3" v-else />
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        modelValue: {
            required: true,
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'md',
            validator: function (value) {
                return ['xs', 'sm', 'md', 'lg'].indexOf(value) !== -1
            }
        },
        shadow: {
            type: String,
            default: 'default',
            validator: function (value) {
                return ['none', 'default', 'sm', 'md', 'lg'].indexOf(value) !== -1
            }
        },
        textsize: {
            type: String,
            default: 'default',
            validator: function (value) {
                return ['default', 'sm', 'md', 'lg'].indexOf(value) !== -1
            }
        },
        min: {
            type: Number,
            default: 0
        },
        bold: {
            type: Boolean,
            default: false
        },
        showicon: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        val: ""
    }),
    mounted() {
        this.val = this.modelValue;
    },
    computed: {
        classes() {
            var shadow = '';
            if (this.shadow === 'default') {
                shadow = 'shadow';
            } else if (this.shadow === 'sm') {
                shadow = 'shadow-sm';
            } else if (this.shadow === 'md') {
                shadow = 'shadow-md';
            } else if (this.shadow === 'lg') {
                shadow = 'shadow-lg';
            }

            var readonly = '';
            if (this.readonly) {
                readonly = 'bg-gray-400';
            }

            var textsize = '';
            if (this.textsize !== 'default') {
                textsize = 'text-' + this.textsize
            }
            if (this.size === 'xs') {
                return 'py-2 px-1 text-xs ' + shadow + ' ' + textsize + ' ' + readonly;
            } else if (this.size === 'sm') {
                return 'py-2 px-2 text-xs ' + shadow + ' ' + textsize + ' ' + readonly;
            } else if (this.size === 'md') {
                return 'py-3 px-3 text-sm ' + shadow + ' ' + textsize + ' ' + readonly;
            } else if (this.size === 'lg') {
                return 'py-3 px-5 text-md ' + shadow + ' ' + textsize + ' ' + readonly;
            }
            return '';
        }
    },
    methods: {
        OnInput(e) {
            this.val = e.target.value
            this.$emit('input', e.target.value)
        },
        OnBlur(e) {
            this.$emit('blur', e)
        },
        OnChange(e) {
            this.$emit('change', e)
        },
        OnFocus(e) {
            this.$emit('focus', e)
        }
    },
    watch: {
        modelValue(val) {
            this.val = this.modelValue;
        },
        val(value) {
            this.$emit('update:modelValue', value)
        }
    },
}
</script>

<style>

</style>

<!--<template>
<div class="flex">
    <div class="form-check red flex items-center rounded-l border-r border-gray-300 border-opacity-25" title="0">
        <label class="block text-center form-check-label py-1 px-2 cursor-pointer">
            0
            <input type="radio" class="form-check-input block w-3" :name="id" value="0" v-model="val">
        </label>
    </div>
    <div class="form-check red flex items-center border-r border-gray-300 border-opacity-25" title="1">
        <label class="block text-center form-check-label py-1 px-2 cursor-pointer">
            1
            <input type="radio" class="form-check-input block w-3" :name="id" value="1" v-model="val">
        </label>
    </div>
    <div class="form-check red flex items-center border-r border-gray-300 border-opacity-25" title="2">
        <label class="block text-center form-check-label py-1 px-2 cursor-pointer">
            2
            <input type="radio" class="form-check-input block w-3" :name="id" value="2" v-model="val">
        </label>
    </div>

    <div class="form-check orange flex items-center border-r border-gray-300 border-opacity-25" title="3">
        <label class="block text-center form-check-label py-1 px-2 cursor-pointer">
            3
            <input type="radio" class="form-check-input block w-3" :name="id" value="3" v-model="val">
        </label>
    </div>
    <div class="form-check orange flex items-center border-r border-gray-300 border-opacity-25" title="4">
        <label class="block text-center form-check-label py-1 px-2 cursor-pointer">
            4
            <input type="radio" class="form-check-input block w-3" :name="id" value="4" v-model="val">
        </label>
    </div>

    <div class="form-check green flex items-center border-r border-gray-300 border-opacity-25" title="5">
        <label class="block text-center form-check-label py-1 px-2 cursor-pointer">
            5
            <input type="radio" class="form-check-input block w-3" :name="id" value="5" v-model="val">
        </label>
    </div>
    <div class="form-check green flex items-center border-r border-gray-300 border-opacity-25" title="6">
        <label class="block text-center form-check-label py-1 px-2 cursor-pointer">
            6
            <input type="radio" class="form-check-input block w-3" :name="id" value="6" v-model="val">
        </label>
    </div>
    <div class="form-check green flex items-center border-r border-gray-300 border-opacity-25" title="7">
        <label class="block text-center form-check-label py-1 px-2 cursor-pointer">
            7
            <input type="radio" class="form-check-input block w-3" :name="id" value="7" v-model="val">
        </label>
    </div>

    <div class="form-check green flex items-center rounded-r" title="8">
        <label class="block text-center form-check-label py-1 px-2 cursor-pointer">
            8
            <input type="radio" class="form-check-input block w-3" :name="id" value="8" v-model="val">
        </label>
    </div>
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: String
        }
    },
    data: () => ({
        id: '',
        val: ''
    }),
    mounted() {
        this.val = this.value;
        this.id = '_' + Math.random().toString(36).substr(2, 9);
    },
    watch: {
        value(val) {
            this.val = val;
        },
        val(value) {
            this.$emit('input', value)
        }
    }
}
</script>

<style>

</style>
-->
