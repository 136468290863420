<template>
    <ion-content class="">
        <ion-list class="pr-4">
            <ion-list-header>Options</ion-list-header>
            <ion-item class="pl-0">{{theme}}</ion-item>
            <ion-item class="pl-0">Test2</ion-item>
            <ion-item class="pl-0">Test3</ion-item>
            <ion-item lines="none" detail="false" class="pl-0">Close</ion-item>
        </ion-list>
    </ion-content>
</template>

<script>
import {
    IonContent,
    IonList,
    IonListHeader,
    IonItem,
} from '@ionic/vue';
import {
    defineComponent
} from 'vue';

export default defineComponent({
    name: 'Popover',
    components: {
        IonContent,
        IonList,
        IonListHeader,
        IonItem
    },
    methods: {
    },
    computed: {
        theme() {
            var bodyEl = document.getElementsByTagName('body')[0];
            if(bodyEl.classList.toString().includes('dark')) {
                return "Light Mode"
            } else {
                return "Dark Mode"
            }
        }
    }
});
</script>
