<template>
    <div v-for="(photo, index) in images" :key="index">
        <div class="flex gap-3">
            <q-input class="w-64" v-model="photo.name" placeholder="Filename..."></q-input>
            <q-input v-model="photo.note" placeholder="Note..."></q-input>
            <q-btn size="small" @click="OpenCameraImage(index, $event)" :disabled="photo.image !== ''">
                <i class="ic ic-camera pointer-events-none"></i>
                <!--<input :ref="'imgFileInput'+index" class="h-0 w-0" type="file" capture="user" accept="image/*" @change="UpdateImgData(index, $event)">-->
                <input ref="imgFileInputRefs" class="h-0 w-0" type="file" capture="user" accept="image/*" @change="UpdateImgData(index, $event)">
                <span class="ml-2" v-if="photo.image !== ''">Image Taken</span>
            </q-btn>
            <q-btn class="ml-auto" size="small" fill="outline" @click="RemoveImage(index)">Remove</q-btn>
        </div>
    </div>
    <q-btn @click="AddPhoto">Add Photo</q-btn>
</template>
<script setup>
const props = defineProps(['type'])
import {ref} from "vue";
const images = ref([]);
const imgFileInputRefs = ref([]);
const OpenCameraImage = (index, event) => {
    const reference = "imgFileInput" + index;
    imgFileInputRefs.value[index].click()
}
const RemoveImage = (index) => {
    images.value.splice(index, 1);
}
const AddPhoto = () => {
    images.value.push({
        name: "",
        note: "",
        image: "",
        type: props.type,
    })
}

const UpdateImgData = (index, event) => {
    images.value[index].image = event.target.files[0];
}
defineExpose({images});
</script>
