import { createApp } from 'vue'
import * as Sentry from '@sentry/capacitor';
import * as SentrySibling from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import App from './App.vue'
import router from './router';
import { createPinia } from 'pinia'
import { IonicVue } from '@ionic/vue';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import axios from 'axios';
import _ from 'lodash';
import VueSignaturePad from "vue-signature-pad";
import VueSlider from "vue-slider-component";
import Vue3TouchEvents from "vue3-touch-events";
import "@capacitor-community/camera-preview";

/* Core CSS required for Ionic components to work properly */
import "./theme/tailwind.scss";
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import "./theme/style.scss";
import "vue-slider-component/theme/default.css";
import './assets/tailwind.css'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate);
window.app_version = "1.0";
const app = createApp(App)
	.use(IonicVue, {

	})
	.use(pinia)
	.use(router)
	.use(VueSignaturePad)
	.use(Vue3TouchEvents);

Sentry.init(
	{
		app,
		dsn: 'https://6126bc583d1746a7b203486dd45e7c65@sentry.darksidedev.net/23',
		dist: '1',
		tracesSampleRate: 0.1,
		integrations: [
			new BrowserTracing({
				tracingOrigins: ['localhost'],
			}),
		]
	},
	SentrySibling.init
);

//const apiUrl = "https://darkside_api.test";
const apiUrl = "https://api.darkside-developments.com";
axios.defaults.timeout = 180000;
app.config.globalProperties.$apiUrl = apiUrl;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$checklistTempFolder = '1hIZKaRKjt4VUNmB3aCVDEWs7xd7jr-X5';
app.config.globalProperties.$_ = _;
app.component('q-card', require('./components/elements/Card').default)
app.component('q-select', require('./components/elements/Select').default)
app.component('q-input', require('./components/elements/Input').default)
app.component('q-btn', require('./components/elements/Btn').default)
app.component('q-editor', require('./components/elements/Editor').default)
app.component('q-icon', require('./components/elements/Icon').default)
app.component('pinPad', require('./components/elements/PinPad').default)
app.component('buttonCheck', require('./components/ButtonCheck').default)
app.component('buttonRadio', require('./components/ButtonRadio').default)
app.component('datePick', require('./components/elements/Datepicker').default)
app.component("colourSelect", require("./components/ColourSelect").default);
app.component("helpText", require("./components/HelpText").default);
app.component("sigpad", require("./components/SigPad").default);
app.component("tabBar", require("./components/TabBar").default);
app.component("fieldSection", require("./components/FieldSection").default);
app.component("psiInput", require("./components/PSIInput").default);
app.component("treadDepth", require("./components/TreadDepth").default);
app.component("userChip", require("./components/UserChip").default);
app.component("search", require("./components/Search").default);
app.component("numberInput", require("./components/elements/NumberInput").default);
app.component("spinner", require("./components/elements/Spinner").default);
app.component("VueSlider", VueSlider);
const WARN_TYPE = {
    VALUE: "value",
    VALUES: "values",
    OPTVALUES: "optvalues",
    NOTES: "notes",
    OPTNOTES: "optnotes",
    OPTNOTESFOCUS: "optnotesfocus",
    INTERVAL: "interval",
    PSI: "psi",
    TREADDEPTH: "treaddepth",
    VALMSG: "valmsg",
};
app.mixin({
    data() {
        return {
			NOTE_SEP: " - ",
			WARN_POSTFIX_OUTS: " - Out of Specification",
			users: [],
			warningFields: {
				quickFaultScan: {
					warnVals: ["Investigate"],
					warnType: WARN_TYPE.VALMSG,
					warnMessage: "ENGINE FAULTS FOUND",
				},
				fullFaultScan: {
					warnVals: ["Investigate"],
					warnType: WARN_TYPE.VALMSG,
					warnMessage: "ENGINE FAULTS FOUND",
				},
				turboNoise: {
					warnVals: ["Red", "Orange", "Yellow"],
					warnType: WARN_TYPE.OPTVALUES,
				},
				lightsOnDashboardCheck: {
					warnVals: ["Yes"],
					warnType: WARN_TYPE.VALUES,
				},
				serviceIndicatorPresent: {
					warnVals: ["Yes"],
					warnType: WARN_TYPE.VALUE,
				},
				generalConditionOfTyres: {
					warnVals: ["Red", "Orange"],
					warnOptions: {
						Orange: "Close to legal limit",
						Red: "Below legal limit",
					},
					warnType: WARN_TYPE.OPTNOTES,
				},
				camTiming: {
					warnVals: ["Orange", "Red"],
					warnType: WARN_TYPE.OPTVALUES,
				},
				injectorValues: {
					warnVals: ["Orange", "Red"],
					warnType: WARN_TYPE.OPTVALUES,
				},
				tempSensor: {
					warnVals: ["High", "Low"],
					warnType: WARN_TYPE.OPTVALUES,
				},
				preTurboEgt: {
					warnVals: ["Faulty"],
					warnType: WARN_TYPE.OPTVALUES,
				},
				dpfBackPressure: {
					warnIntervals: [
						[501, "If value over 500 add to note - DPF Back Pressure <value>mb at full load, regeneration needed"],
						[401, "If value over 400 add to notes - DPF Back Pressure <value>mb at full load, regneration may be required soon"],
					],
					warnType: WARN_TYPE.INTERVAL,
				},
				dpfSootLoading: {
					warnIntervals: [
						[41, "If value over 40 add to note - DPF Soot Loading <value>grams at idle, regeneration needed"],
						[31, "If value over 30 add to notes - DPF Soot Loading <value>grams at idle, regneration may be required soon"],
					],
					warnType: WARN_TYPE.INTERVAL,
				},
				maf: {
					//warnVals: ["Reads Low", "Idle OK,Reads Low", "Free Revving OK,Reads Low", "Idle OK,Free Revving OK,Reads Low"],
					warnVals: ["Reads Low", "Idle OK,Reads Low", "Free Revving OK,Reads Low", "Free Revving OK,Idle OK,Reads Low"],
					warnType: WARN_TYPE.VALUES,
				},
				tracking: {
					warnVals: ["Red", "Orange"],
					warnType: WARN_TYPE.VALMSG,
					warnName: "TRACKING",
					warnMessages: {
						Red: {
							message: "TRACKING - Urgent Attention Required",
							postfix: false,
						},
						Orange: {
							message: "TRACKING",
						},
					},
				},
				cycleGears: {
					warnVals: ["Red", "Orange"],
					warnType: WARN_TYPE.OPTNOTES,
					warnMessage: "GEARBOX",
				},
				strangeNoises: {
					warnVals: ["Red", "Orange"],
					warnType: WARN_TYPE.VALMSG,
					warnMessage: "Road test Noises",
				},
				analyseLogs: {
					warnVals: ["Red", "Orange"],
					warnType: WARN_TYPE.VALMSG,
					warnMessage: "ROAD LOGS",
				},
				engineOil: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				brakeFluid: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				coolantTemp: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				powerSteering: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				screenwash: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				indicatorsHazard: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				dippedMainBeam: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				sidelights: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				frontFog: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				rearFog: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				numberPlateLightAndCondition: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				brake: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				reverse: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				wiperBladesWashWipe: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				windscreen: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				mirrorsIncRearView: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				horn: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				frontWindowsOpen: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				structureAndIntegrityOfChassis: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				frontShockAbsorbers: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				frontShockerTops: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				rearShockAbsorbers: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				rearShockTops: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				frontSuspensionBushes: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				rearSuspensionBushes: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				steeringRackAndTrackRodEnds: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				cvBootsAndDriveshafts: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				wheelBearings: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				engineMounts: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				exhaustLeaksMountings: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				frontBrakeDiscsPads: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				rearBrakeDiscsPads: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				frontBrakeHosesPipes: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				rearBrakeHosesPipes: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				handbrake: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				frontRightTyre: {
					warnVals: [3],
					warnType: WARN_TYPE.PSI,
				},
				rearLeftTyre: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				frontLeftTyre: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				rearRightTyre: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				spareTyre: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				frontRightTyrePsi: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				rearLeftTyrePsi: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				frontLeftTyrePsi: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				rearRightTyrePsi: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				frontRightTyreCorrectPsi: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				rearLeftTyreCorrectPsi: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				frontLeftTyreCorrectPsi: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				rearRightTyreCorrectPsi: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				frontRightTyrePsiCorrected: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				rearLeftTyrePsiCorrected: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				frontLeftTyrePsiCorrected: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				rearRightTyrePsiCorrected: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				spareTyrePsiCorrected: {
					warnVals: ["0", "1", "2", "3", "4"],
					warnType: WARN_TYPE.PSI,
				},
				conditionAndMountingOfBumpers: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				conditionOfLights: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				doorHandlesAndLocks: {
					warnVals: ["Urgent", "Recommended"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				compressorWheelCheck: {
					warnVals: ["Minor Damage", "Major Damage"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				checkTurbochargerThrust: {
					warnVals: ["Red", "Orange"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				checkTurbochargerRadial: {
					warnVals: ["Red", "Orange"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				checkActuatorLength: {
					warnVals: ["Red", "Orange"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				vacuumSystemCheck: {
					warnVals: ["Red", "Orange"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				tandemCheck: {
					warnVals: ["Red", "Orange"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				boostLeakCheck: {
					warnVals: ["Leak Repaired", "Minor Leak", "Major Leak"],
					warnType: WARN_TYPE.OPTNOTESFOCUS,
				},
				gears: {
					warnVals: ["Red", "Orange"],
					warnType: WARN_TYPE.OPTNOTES,
				},
				checkLastServiceDistance: {
					warnIntervals: [[6001, "Ask Customer - LL Oil?"]],
					warnType: WARN_TYPE.INTERVAL,
					warnTitle: "Distance since last service",
				},
				checkLastServiceTime: {
					warnIntervals: [
						["OK", 179],
						[180, "Ask Customer", 270],
						[271, "Service Now"],
					],
					warnType: WARN_TYPE.INTERVAL,
					warnTitle: "Time since last service",
				},
			},
		};
    },
    methods: {
        NoteIntervalUpdate(warn, name, val, old, generatedNotes, form) {
            const NOTE_SEP = this.NOTE_SEP;
            const value = parseFloat(val)
            let newWarning,
                isInInterval = false,
                interval,
                isLeftBound,
                isRightBound,
                warnText = "";
            let indx;
            const intervals = warn ? warn.warnIntervals : [];
            const warnTitle = warn ? warn.warnTitle || name : undefined;
            for (let i = 0; i < intervals.length; i++) {
                interval = intervals[i];
                isRightBound = typeof interval[0] === "number";
                isLeftBound = typeof interval[interval.length - 1] === "number";
                if (isLeftBound && isRightBound) {
                    if (interval[0] <= value && value <= interval[2]) {
                        isInInterval = true;
                        warnText = interval[1];
                        break;
                    } else {
                        continue;
                    }
                } else if (isLeftBound && value <= interval[1]) {
                    isInInterval = true;
                    warnText = interval[0];
                    break;
                } else if (isRightBound && interval[0] <= value) {
                    isInInterval = true;
                    warnText = interval[1];
                    break;
                }
            }

            if (isInInterval) {
                indx = generatedNotes.findIndex((el) => _.startsWith(el, warnTitle + " - "));
                if (indx > -1) {
                    generatedNotes.splice(indx, 1);
                }
                newWarning = warnTitle + NOTE_SEP + warnText.replace("<value>", value);
                generatedNotes.push(newWarning);
            } else {
                indx = generatedNotes.findIndex((el) => _.startsWith(el, warnTitle + " - "));
                if (indx > -1) {
                    generatedNotes.splice(indx, 1);
                }
            }
            return generatedNotes;
        },
        NoteValueUpdate(warn, name, val, old, generatedNotes, form) {
            let newWarning;
            const NOTE_SEP = this.NOTE_SEP;
            let indx;
            if (warn && warn.warnVals.indexOf(val) !== -1) {
                newWarning = name + NOTE_SEP + val;
                indx = generatedNotes.findIndex((el) => _.startsWith(el, name + NOTE_SEP));
                if (indx > -1) {
                    generatedNotes.splice(indx, 1);
                }
                generatedNotes.push(newWarning);
            } else {
                indx = generatedNotes.findIndex((el) => _.startsWith(el, name + NOTE_SEP));
                if (indx > -1) {
                    generatedNotes.splice(indx, 1);
                }
            }
            return generatedNotes;
        },
        NoteValuesUpdate(warn, name, val, old, generatedNotes, form) {
            let newWarning;
            const NOTE_SEP = this.NOTE_SEP;
            const WARN_POSTFIX_OUTS = this.WARN_POSTFIX_OUTS;
            let indx;
            if (warn && warn.warnVals.indexOf(val) !== -1) {
                newWarning = name + WARN_POSTFIX_OUTS;
                // newWarning = name + NOTE_SEP + val;
                indx = generatedNotes.findIndex((el) => _.startsWith(el, name + NOTE_SEP));
                if (indx > -1) {
                    generatedNotes.splice(indx, 1);
                }
                generatedNotes.push(newWarning);
            } else {
                indx = generatedNotes.findIndex((el) => _.startsWith(el, name + NOTE_SEP));
                if (indx > -1) {
                    generatedNotes.splice(indx, 1);
                }
            }
            return generatedNotes;
        },
        NoteOptNotesUpdate(warn, name, val, old, generatedNotes, form) {
            const descInput = name.toLowerCase().replace(/\s/g, "_") + "_desc";
            const desc = form[descInput];
            let indx;
            let newWarning;
            const NOTE_SEP = this.NOTE_SEP;
            const oldName = name;
            if (warn && warn.warnVals.indexOf(val) !== -1) {
                if (warn.warnOptions) {
                    name = name + " (" + warn.warnOptions[val] + ")";
                } else {
                    name = name + " (" + val + ")";
                }
                newWarning = name + (desc ? NOTE_SEP + desc : "");
                indx = generatedNotes.findIndex((el) => _.startsWith(el, oldName));
                if (indx > -1) {
                    generatedNotes.splice(indx, 1);
                }
                generatedNotes.push(newWarning);
            } else {
                indx = generatedNotes.findIndex((el) => _.startsWith(el, oldName));
                if (indx > -1) {
                    generatedNotes.splice(indx, 1);
                }
            }
            return generatedNotes;
        },
        NoteOptValuesUpdate(warn, name, val, old, generatedNotes, form) {
            let title, newWarning, indx;
            const WARN_POSTFIX_OUTS = this.WARN_POSTFIX_OUTS;
            if (warn && warn.warnVals.indexOf(val) !== -1) {
                title = name;
                title += " (" + val + ")";
                newWarning = title + WARN_POSTFIX_OUTS;
                indx = generatedNotes.findIndex((el) => _.startsWith(el, name));
                if (indx > -1) {
                    generatedNotes.splice(indx, 1);
                }
                generatedNotes.push(newWarning);
            } else {
                indx = generatedNotes.findIndex((el) => _.startsWith(el, name));
                if (indx > -1) {
                    generatedNotes.splice(indx, 1);
                }
            }
            return generatedNotes;
        },
        NoteValMessageUpdate(warn, name, val, old, generatedNotes, form) {
            let warnPostfix, newWarning, defaultWarnMsg, warnMsg, indx;
            const WARN_POSTFIX_OUTS = this.WARN_POSTFIX_OUTS;
            if (warn && warn.warnVals.indexOf(val) !== -1) {
                if (warn.warnMessage) {
                    defaultWarnMsg = { message: warn.warnMessage, postfix: warn.warnPostfix };
                    warnMsg = defaultWarnMsg;
                }
                if (warn.warnMessages) {
                    warnMsg = warn.warnMessages[val] || defaultWarnMsg;
                }
                warnPostfix = warnMsg.postfix === false ? "" : WARN_POSTFIX_OUTS;
                newWarning = warnMsg.message + warnPostfix;

                if (warn.warnMessage || warn.warnMessages) {
                    if (warn.warnMessage) {
                        indx = generatedNotes.findIndex((el) => _.startsWith(el, warn.warnMessage));
                        if (indx > -1) {
                            generatedNotes.splice(indx, 1);
                        }
                    }
                    if (warn.warnMessages) {
                        indx = generatedNotes.findIndex((el) => _.startsWith(el, warn.warnName));
                        if (indx > -1) {
                            generatedNotes.splice(indx, 1);
                        }
                    }
                } else {
                    indx = generatedNotes.findIndex((el) => _.startsWith(el, name));
                    if (indx > -1) {
                        generatedNotes.splice(indx, 1);
                    }
                }

                generatedNotes.push(newWarning);
            } else {
                if (warn.warnMessage || warn.warnMessages) {
                    if (warn.warnMessage) {
                        indx = generatedNotes.findIndex((el) => _.startsWith(el, warn.warnMessage));
                        if (indx > -1) {
                            generatedNotes.splice(indx, 1);
                        }
                    }
                    if (warn.warnMessages) {
                        indx = generatedNotes.findIndex((el) => _.startsWith(el, warn.warnName));
                        if (indx > -1) {
                            generatedNotes.splice(indx, 1);
                        }
                    }
                } else {
                    indx = generatedNotes.findIndex((el) => _.startsWith(el, name));
                    if (indx > -1) {
                        generatedNotes.splice(indx, 1);
                    }
                }
            }
            return generatedNotes;
        },
        NotePSIUpdate(warn, name, val, old, generatedNotes, form) {
            let notes = "";
            const nme = name.split("Tyre")[0];
            const nameSplit = nme.toLowerCase().replace(/\s/g, "_");
            const treadDepth = form[nameSplit + "tyre"];
            const desc = form[nameSplit + "tyre_desc"];
            const currPSI = form[nameSplit + "tyre_psi"];
            const correctPSI = form[nameSplit + "tyre_correct_psi"];
            const corrected = form[nameSplit + "tyre_psi_corrected"];
            let isCorrected = "";

            if (treadDepth < 1.6) {
                notes = notes + nme + "Tyre (Below Legal Limit - " + treadDepth + "mm)";
            } else if (treadDepth >= 1.6 && treadDepth < 3) {
                notes = notes + nme + "Tyre (Close to Legal Limit - " + treadDepth + "mm)";
            }

            if (corrected) {
                isCorrected = "- CORRECTED";
            } else {
                isCorrected = "";
            }

            if (currPSI < 28) {
                if (notes === "") {
                    notes = notes + nme + "Tyre PSI (Incorrect Tyre Pressure " + currPSI + " psi, correct tyre pressure: " + correctPSI + " psi) " + isCorrected;
                } else {
                    notes = notes + " - " + nme + "Tyre PSI (Incorrect Tyre Pressure " + currPSI + " psi, correct tyre pressure: " + correctPSI + " psi) " + isCorrected;
                }
            }
            if (desc !== "") {
                notes = notes + " - " + desc;
            }
            const indx = generatedNotes.findIndex((el) => _.startsWith(el, nme + "Tyre"));
            if (indx > -1) {
                generatedNotes.splice(indx, 1);
            }

            generatedNotes.push(notes);
            return generatedNotes;
        },
        NoteTreadDepthUpdate(warn, name, val, old, generatedNotes, form) {
            let correctPsiInput;
            //let correctPsi;
            const descInput = name.toLowerCase().replace(/\s/g, "_") + "_desc";
            const desc = form[descInput];
            let indx;
            let newWarning;
            const NOTE_SEP = this.NOTE_SEP;
            const oldName = name;
            if (warn && warn.warnVals > val) {
                if (!name.includes("Psi") && val < 1.6) {
                    val = "Below Legal Limit (" + val + "mm)";
                } else if (!name.includes("Psi") && val >= 1.6 && val <= 3) {
                    val = "Close to Legal Limit (" + val + "mm)";
                }

                if (warn.warnOptions) {
                    name = name + " (" + warn.warnOptions[val] + ")";
                } else {
                    name = name + " (" + val + ")";
                }

                if (name.includes("Psi")) {
                    correctPsiInput = name.toLowerCase().replace(/\s/g, "_") + "_correct_psi";
                    const correctPsi = form[correctPsiInput];
                    console.log(correctPsi)
                }
                // newWarning = name + (currPsi ? NOTE_SEP + currPsi + ' current psi' : '');
                // newWarning = newWarning + (correctPsi ? NOTE_SEP + correctPsi + ' correct psi' : '');
                newWarning = name + (desc ? NOTE_SEP + desc : "");
                indx = generatedNotes.findIndex((el) => _.startsWith(el, oldName));
                if (indx > -1) {
                    generatedNotes.splice(indx, 1);
                }
                generatedNotes.push(newWarning);
            } else {
                indx = generatedNotes.findIndex((el) => _.startsWith(el, oldName));
                if (indx > -1) {
                    generatedNotes.splice(indx, 1);
                }
            }
            // console.log(generatedNotes)
            return generatedNotes;
        },
		dataURLtoFile(dataurl, filename) {
			let arr = dataurl.split(','),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[arr.length - 1]),
				n = bstr.length,
				u8arr = new Uint8Array(n);
			while(n--){
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, {type:mime});
		},
		dataURLToBlob(dataURL) {
			const parts = dataURL.split(',');
			const meta = parts[0].substring(5).split(';');
			const type = meta[0];
			const decoder = meta.indexOf('base64') !== -1 ? atob : decodeURIComponent;
			const bstr = decoder(parts[1]);
			let n = bstr.length;
			const u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new Blob([u8arr], { type: type });
		}
    },
});
router.isReady().then(() => {
  app.mount('#app');
});
