import {defineStore} from "pinia";
import _ from "lodash";
const initialState = {
    reg: '',
    name: '',
    checkCompletedBy: '',
    folderId: '',
    bookingFormID: '',
    notes: '',
    fullscanStarted: '',

    engineOil: '',
    engineOilDesc: '',
    brakeFluid: '',
    brakeFluidDesc: '',
    coolantTemp: '',
    coolantTempDesc: '',
    powerSteering: '',
    powerSteeringDesc: '',
    screenwash: '',
    screenwashDesc: '',

    indicatorsHazard: '',
    indicatorsHazardDesc: '',
    dippedMainBeam: '',
    dippedMainBeamDesc: '',
    sidelights: '',
    sidelightsDesc: '',
    frontFog: '',
    frontFogDesc: '',
    rearFog: '',
    rearFogDesc: '',
    numberPlateLightAndCondition: '',
    numberPlateLightAndConditionDesc: '',
    brake: '',
    brakeDesc: '',
    reverse: '',
    reverseDesc: '',
    wiperBladesWashWipe: '',
    wiperBladesWashWipeDesc: '',
    windscreen: '',
    windscreenDesc: '',
    mirrorsIncRearView: '',
    mirrorsIncRearViewDesc: '',
    horn: '',
    hornDesc: '',
    frontWindowsOpen: '',
    frontWindowsOpenDesc: '',

    structureAndIntegrityOfChassis: '',
    structureAndIntegrityOfChassisDesc: '',
    frontShockAbsorbers: '',
    frontShockAbsorbersDesc: '',
    frontShockerTops: '',
    frontShockerTopsDesc: '',
    rearShockAbsorbers: '',
    rearShockAbsorbersDesc: '',
    rearShockerTops: '',
    rearShockerTopsDesc: '',
    frontSuspensionBushes: '',
    frontSuspensionBushesDesc: '',
    rearSuspensionBushes: '',
    rearSuspensionBushesDesc: '',
    steeringRackAndTrackRodEnds: '',
    steeringRackAndTrackRodEndsDesc: '',
    cvBootsAndDriveshafts: '',
    cvBootsAndDriveshaftsDesc: '',
    wheelBearings: '',
    wheelBearingsDesc: '',
    engineMounts: '',
    engineMountsDesc: '',
    exhaustLeaksMountings: '',
    exhaustLeaksMountingsDesc: '',
    frontBrakeDiscsPads: '',
    frontBrakeDiscsPadsDesc: '',
    rearBrakeDiscsPads: '',
    rearBrakeDiscsPadsDesc: '',
    frontBrakeHosesPipes: '',
    frontBrakeHosesPipesDesc: '',
    rearBrakeHosesPipes: '',
    rearBrakeHosesPipesDesc: '',
    handbrake: '',
    handbrakeDesc: '',

    frontRightTyre: '0',
    frontLeftTyre: '0',
    rearLeftTyre: '0',
    rearRightTyre: '0',
    spareTyre: '0',
    frontRightTyrePsi: '0',
    frontRightTyreCorrectPsi: '0',
    frontRightTyrePsiCorrected: false,
    frontLeftTyrePsi: '0',
    frontLeftTyreCorrectPsi: '0',
    frontLeftTyrePsiCorrected: false,
    rearRightTyrePsi: '0',
    rearRightTyreCorrectPsi: '0',
    rearRightTyrePsiCorrected: false,
    rearLeftTyrePsi: '0',
    rearLeftTyreCorrectPsi: '0',
    rearLeftTyrePsiCorrected: false,
    spareTyrePsi: '0',
    spareTyreCorrectPsi: '0',
    spareTyrePsiCorrected: false,
    frontRightTyreDesc: '',
    frontLeftTyreDesc: '',
    rearRightTyreDesc: '',
    rearLeftTyreDesc: '',
    spareTyreDesc: '',

    conditionAndMountingOfBumpers: '',
    conditionAndMountingOfBumpersDesc: '',
    conditionOfLights: '',
    conditionOfLightsDesc: '',
    doorHandlesAndLocks: '',
    doorHandlesAndLocksDesc: '',
    faultScanComplete: [],
    photos: []
}
export const useWorkshopStore = defineStore('workshopStore',{
    state: () => ({ ...initialState }),
    //state: () => _.cloneDeep(initialState),
    actions: {
        async clearAll() {
            localStorage.removeItem('workshopStore')
            Object.assign(this, _.cloneDeep(initialState))
        }
    },
    persist: false
});
