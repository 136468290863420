<template>
    <form class="login" id="pinpad">
        <h3>Account Login</h3>
        <p>Please enter your 4-digit PIN code</p>
        <fieldset>
            <ul class="pin-ui" @click="pinInput.focus()">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <div class="mask"></div>
            <input type="password" inputmode="numeric" name="input-pin" class="input-pin" id="input-pin" @keydown="keyDown">
        </fieldset>
    </form>
</template>

<script>
export default {
    props: ['user'],
    data() {
        return {
            loginForm: null,
            pinInput: null,
            pinUI: null,
            numChars: 0,
            acceptInput: true,
        }
    },
    mounted() {
        this.loginForm = document.querySelector('form.login');
        this.pinInput = document.getElementById('input-pin');
        this.pinUI = document.querySelectorAll('.pin-ui li');
        this.numChars = 0;
        this.acceptInput = true;
        this.pinInput.focus();
    },
    methods: {
        keyDown(e) {
            if (!this.acceptInput) {
                e.preventDefault();
            } else {
                var keycodes = [48,49,50,51,52,53,54,55,56,57,229]
                if (keycodes.includes(e.keyCode)) {
                    this.numChars++;
                } else if(e.keyCode == 8) {
                    this.numChars--;
                }
                this.pinUI.forEach((el, i) => {
                    if (this.numChars > i) {
                        el.classList.add('on');
                    } else {
                        el.classList.remove('on');
                    }
                });

                if (this.numChars === 4) {
                    setTimeout(() => {
                        this.submit()
                    }, 500)
                }
            }
        },
        submit() {
            this.acceptInput = false;
            this.pinInput.blur();
            document.querySelector('.mask').classList.add('visible');
            setTimeout(() => {
                this.checkPin().then(() => {
                    console.log('done')
                    this.$emit('authed', {"user": this.user})
                }).catch(err => {
                    this.numChars = 0;
                    this.acceptInput = true;
                    document.getElementById('input-pin').value = ""
                    document.getElementById('pinpad').classList.add('shake')
                    setTimeout(() => {
                        this.pinUI.forEach(function (el, i) {
                            el.classList.remove('on');
                        });
                        document.getElementById('pinpad').classList.remove('shake')
                        document.querySelector('.mask').classList.remove('visible');
                        setTimeout(() => {
                            console.log(this.pinInput)
                            this.pinInput.blur();
                            this.pinInput.focus();
                        }, 500);
                    }, 500);
                });
            }, 500);
        },
        checkPin() {
            return new Promise((resolve, reject) => {
                var pin = document.getElementById('input-pin').value;
                this.$axios.post(this.$apiUrl + '/api/user/pin/check', {
                    pin: pin,
                    user_id: this.user.value
                }).then((resp) => {
                    if(resp.data.success) {
                        resolve();
                    } else {
                        reject();
                    }
                }).catch(err => {
                    reject();
                })
            });
        },

    },
    unmounted() {
        //document.removeEventListener('keydown')
    }
}
</script>

<style lang="scss" scoped>
$blue: #143968;
$green: #7fd89a;
$red: #ca5b5b;

form.login {
    position: absolute;
    top: 45%;
    left: 50%;
    z-index: 5;
    transform: translate(-50%, -50%);
    background: var(--ion-card-color);
    padding: 40px;
    display: flex;
    flex-direction: column;
    width: 400px;
    max-width: 90%;
    overflow: hidden;
    text-align: center;
    box-shadow: 0px 10px 30px -15px black;
    border-radius: 5px;
    transition: 400ms;
    h3 {
        font-weight: 600;
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    p {
        opacity: 0.5;
    }

    fieldset {
        display: flex;
        flex-direction: column;
        position: relative;
        .mask {
            position: absolute;
            background: red;
            top: calc(100% + 10px);
            left: 50%;
            transform: translateX(-50%) scaleX(0);
            height: 100px;
            width: 100%;
            max-width: 130px;
            z-index: 20;
            background: white;
            border-top: 1px solid #e0e0e0;
            opacity: 0;
        }

        .mask.visible {
            opacity: 1;
            transition: 400ms 500ms;
            transform: translateX(-50%) scaleX(1);
        }
    }
    .input-pin {
        position: absolute;
        left: -1000px;
    }
    .input-pin:focus {
        outline: none;
    }
    input[type=submit] {
        display: none;
    }
}

form.hide {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 0;
    pointer-events: none;
}

.pin-ui {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px 0 0 0;
    li {
        width: 12px;
        height: 12px;
        background: #ddd;
        border-radius: 100%;
        margin: 0 6px;
        transition: transform 200ms;
        border: 1px solid rgba(0, 0, 0, 0.1);
        will-change: transform;
    }

    li.on {
        background: $green;
        transform: scale(1.4);
    }

    li.animate {
        animation: pip 1s 1 forwards;
    }

    li.animate-rev {
        background: #ddd;
        animation: pipRev 1s 1 backwards;
    }
}

.hidden-links {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    a {
        display: block;
        color: white;
        text-decoration: none;
    }

    .linkout {
        font-weight: 600;
        font-size: 24px;
        text-shadow: 0 -1px rgba(0, 0, 0, 0.5);
    }

    .linkout:hover {
        text-decoration: underline;
    }

    .reset {
        margin-top: 20px;
        opacity: 0.5;
    }
}
.shake {
  animation: shakeX 0.4s both;
  animation-iteration-count: 2;
  //transform: translate3d(0, 0, 0);
  //backface-visibility: hidden;
  //perspective: 1000px;
}
@keyframes pip {
    0% {
        transform: translateY(0) scale(1.4);
    }

    60% {
        transform: translateY(-75%) scale(1.4);
    }

    99% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(250%) scale(1.4);
    }
}
@keyframes pipRev {
    0% {
        opacity: 0;
        transform: translateY(250%) scale(1.4);
    }

    60% {
        opacity: 1;
    }

    99% {
        transform: translateY(75%) scale(1.4);
    }

    100% {
        transform: translateY(0%) scale(1.4);
    }
}

@keyframes shakeX {
  from,
  to {
    transform: translate(-48%, -50%);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate(-50%, -50%);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate(-52%, -50%);
  }
}

.shakeX {
  animation-name: shakeX;
}
</style>
