<template>
    <ion-page>
        <ion-header :translucent="false">
            <ion-toolbar>
                <ion-title class="font-bold"></ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <ion-header collapse="condense">
                <ion-toolbar>
                    <ion-title size="large">Blank</ion-title>
                </ion-toolbar>
            </ion-header>

            <div id="container">
                <div v-if="jobs.length == 0">
                    <div class="flex gap-3 justify-center mt-10">
                        <div @click="GetJobs('mng')">
                            <img :src="require('../assets/images/checklist_logos/Meet and Greet.png')">
                        </div>
                        <div @click="GetJobs('rt')">
                            <img :src="require('../assets/images/checklist_logos/Road Test.png')">
                        </div>
                        <div @click="GetJobs('wc')">
                            <img :src="require('../assets/images/checklist_logos/Workshop Check.png')">
                        </div>
                    </div>
                    <div class="flex gap-3 justify-center mt-3">
                        <div @click="GetJobs('epv')">
                            <img :src="require('../assets/images/checklist_logos/Add Photos - Videos.png')">
                        </div>
                        <div @click="GetJobs('pwc')">
                            <img :src="require('../assets/images/checklist_logos/Post Work Check.png')">
                        </div>
                        <div @click="GetJobs('frt')">
                            <img :src="require('../assets/images/checklist_logos/Final Road Test.png')">
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="grid grid-cols-3">
                        <ion-card v-for="job in jobs" :key="job.id" @click="GoTo(job.id)">
                            <ion-card-content>
                                <h5 class="font-bold" style="font-weight: 700;">{{job.id}}</h5>
                                <ul>
                                    <li>{{job.name}}</li>
                                    <li>{{job.VRVrm}}</li>
                                    <li>{{job.VRMake}} {{job.VRModel}}</li>
                                    <li v-if="job.phonenumber !== 0">{{job.phonenumber}}</li>
                                    <li>{{job.email}}</li>
                                </ul>
                            </ion-card-content>
                        </ion-card>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardContent
} from '@ionic/vue';
import {
    defineComponent
} from 'vue';
import {usePreWorkStore} from "@/store/preWorkStore";
import {useRoadTestStore} from "@/store/roadTestStore";
import {useWorkshopStore} from "@/store/workshopStore";
import {useExtraPhotosVideosStore} from "@/store/extraPhotosVideosStore";
import {usePostWorkStore} from "@/store/postWorkStore";
import {useFinalRoadTestStore} from "@/store/finalRoadTestStore";
export default defineComponent({
    name: 'Main',
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
        IonCard,
        IonCardContent
    },
    data() {
        return {
            type: null,
            jobs: []
        }
    },
    mounted() {},
    methods: {
        GetJobs(type) {
            usePreWorkStore().$reset();
            this.type = type;
            this.$axios.get(this.$apiUrl + '/v2/checklist/jobs-type?type=' + type).then((resp) => {
                console.log(resp.data)
                this.jobs = resp.data
            }).catch((err) => {
                console.log(err)
            })
        },
        GoTo(id) {
            if(this.type === 'mng') {
                usePreWorkStore().$reset();
                this.$router.push({ path: `/prework/${id}` })
            } else if(this.type === 'rt') {
                useRoadTestStore().$reset();
                this.$router.push({ path: `/roadtest/${id}` })
            } else if(this.type === 'wc') {
                useWorkshopStore().$reset();
                this.$router.push({ path: `/workshop/${id}` })
            } else if(this.type === 'epv') {
                useExtraPhotosVideosStore().$reset();
                this.$router.push({ path: `/extramedia/${id}` })
            } else if(this.type === 'pwc') {
                usePostWorkStore().$reset();
                this.$router.push({ path: `/postwork/${id}` })
            } else if(this.type === 'frt') {
                useFinalRoadTestStore().$reset();
                this.$router.push({ path: `/finalroadtest/${id}` })
            }
        }
    }
});
</script>

<style>

</style>
