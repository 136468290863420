<template>
    <div class="search-wrap">
        <div class="mt-2 relative">
            <i class="linearicons-magnifier absolute left-5 top-3"></i>
            <input class="w-full border border-color rounded py-2 pl-10 pr-16 focus:outline-none" :class="{'rounded-b-none': data.length > 0}" @keyup="OnKeyUp" :placeholder="placeholder" v-model="searchTerm" />
            <button class="absolute top-2 bg-blue-400 px-3 py-1 rounded text-white shadow w-24 h-6" :class="{'right-24': searchTerm.length > 0, 'right-5': searchTerm.length == 0}" @click="Search" style="line-height: 1">
                <template v-if="!isSearching">Search</template>
                <template v-else><i class="linearicons-loading2 anim-rotate"></i></template>
            </button>
            <button class="absolute right-5 top-2 border border-solid border-blue-500 px-3 py-1 rounded text-black shadow h-6" v-if="searchTerm.length > 0" @click="ResetSearch" style="line-height: 1">Clear</button>
        </div>

        <div class="mt-0 relative max-h-72 overflow-y-auto">
            <ul>
                <li class="rounded rounded-t-none px-2 py-2 bg-white border border-t-0 border-color" @click="Selected(d)" v-for="(d, index) in data" :key="index" :class="{'rounded-b-none': index !== data.length - 1}">
                    <div class="whitespace-nowrap overflow-hidden overflow-ellipsis"><slot name="option" :data="d"></slot></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        placeholder: {
            type: String,
            default: ""
        },
        options: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            searchTerm: "",
            isSearching: false,
            data: []
        }
    },
    mounted() {
        console.log(this.options)
    },
    methods: {
        Search() {
            this.isSearching = true;
            this.$axios.post(this.$apiUrl + this.options.url, {
                term: this.searchTerm
            }).then((resp) => {
                if(resp.data.success) {
                    this.data = resp.data.data
                    this.isSearching = false;
                } else {
                    this.isSearching = false;
                    this.data = [];
                }
            }).catch(err => {
                this.isSearching = false;
                console.log(err)
            })
        },
        OnKeyUp(e) {
            if (e.key == "Enter") {
                this.Search();
            }
        },
        Selected(val) {
            this.$emit('selected', val);
        },
        ResetSearch() {
            this.data = [];
            this.searchTerm = ""
        }
    }
}
</script>

<style scoped>
.search-wrap {
    margin-left: 10px;
    margin-right: 10px;
}
.anim-rotate {
    display: inline-block;
    animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>