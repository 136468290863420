<template>
    <ion-button :disabled="disabled" :expand="expand" :size="size" :fill="fill" :color="color" v-if="!customColor"><slot></slot></ion-button>
    <ion-button :disabled="disabled" :expand="expand" :size="size" :fill="fill" :style="{'--background': color}" v-else><slot></slot></ion-button>
</template>

<script>
import {
    IonButton
} from '@ionic/vue';
export default {
    props: {
        expand: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: "default"
        },
        fill: {
            type: String,
            default: "solid"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: "primary"
        }
    },
    data() {
        return {
            customColor: false,
        }
    },
    components: {
        IonButton
    },
    mounted() {
        this.btnColor();
    },
    methods: {
        btnColor() {
            var colors = ["primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", "dark"]
            this.customColor = !colors.includes(this.color);
        }
    },
    watch: {
        color(val) {
            this.btnColor()
        }
    }
}
</script>

<style>

</style>
