<template>
    <div :aria-label="ariaLabel">
        <ion-card>
            <ion-card-header v-if="$slots.header" class="border-b mb-3">
                <slot name="header"></slot>
            </ion-card-header>

            <ion-card-content>
                <slot name="main"></slot>
            </ion-card-content>
        </ion-card>
    </div>
</template>

<script>
import {
    IonCard,
    IonCardHeader,
    IonCardContent
} from '@ionic/vue';
export default {
    components: {
        IonCard,
        IonCardHeader,
        IonCardContent
    },
    props: {
        ariaLabel: String
    }
}
</script>

<style>

</style>
