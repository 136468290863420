<template>
    <editor-content class="border border-color p-2 rounded tiptap-editor" :editor="editor" />
</template>

<script>
import {
    Editor,
    EditorContent
} from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
export default {
    components: {
        EditorContent,
    },
    props: {
        modelValue: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            editor: null,
        }
    },
    mounted() {
        this.editor = new Editor({
            content: this.modelValue,
            extensions: [
                StarterKit,
            ],
            onUpdate: () => {
                this.$emit('update:modelValue', this.editor.getHTML())
            },
        })
    },
    watch: {
        modelValue(value) {
            const isSame = this.editor.getHTML() === value
            if (isSame) {
                return
            }
            this.editor.commands.setContent(value, false)
        },
        beforeUnmount() {
            this.editor.destroy()
        },
    }
}
</script>

<style>
.tiptap-editor .ProseMirror:focus{
    outline:none
}
</style>
