<template>
    <div>
        <div class="flex gap-2 items-centers flex-wrap">
            <button class="btn-radio" v-for="opt in options" :key="opt" @click="SetActive(opt)" :class="{'active': selected.includes(opt)}">{{opt}}</button>
        </div>
        <div class="font-bold text-red-500 mt-1">{{message}}</div>
    </div>
</template>

<script>
import {
    defineComponent
} from 'vue';
export default defineComponent({
    props: {
        modelValue: Array,
        options: Array,
        v: Object
    },
    data() {
        return {
            selected: [],
        }
    },
    mounted() {
        this.selected = this.modelValue;
    },
    methods: {
        SetActive(option) {
            if (this.selected == null || this.selected == "") {
                this.selected = [];
                this.selected.push(option);
            } else {
                var find = this.selected.findIndex(el => el === option);
                if (find > -1) {
                    this.selected.splice(find, 1);
                } else {
                    this.selected.push(option);
                }
            }
            this.$emit('update:modelValue', this.selected)
        }
    },
    watch: {
        modelValue(val) {
            this.selected = val
        }
    },
    computed: {
        message() {
            if (this.v && this.v.$error) {
                for (let key in this.$options.messages) {
                    if (this.v[key].$invalid === true) {
                        return this.$options.messages[key](this.v)
                    }
                }
                return null;
            } else {
                return null
            }
        }
    },
    messages: {
        required: v => 'This field is required',
        email: v => 'Must be a valid e-mail',
        alphaNum: v => 'May only contain letters and numbers',
        minLength: v => 'Must be at least ' + v.$params.minLength.min + ' characters',
        goodPassword: v => '', //The password rules are always displayed, so no need to show a message
        sameAs: v => 'Must be same as ' + v.$params.sameAs.eq
    }
})
</script>

<style lang="scss">
.btn-radio {
    background: var(--ion-color-light-tint);
    border: 1px solid var(--ion-color-light-shade); //#ebecf1;
    color: var(--ion-color-text);
    padding: 5px 20px;
    border-radius: 5px;

    &.active {
        background: #c8e6fd;
        color: #5398c9;
        border-color: #76bbea;
    }
}
</style>
