<template>
    <div>
        <canvas :id="elemId" class="border border-color rounded-lg" width="450" height="150"></canvas>
        <!--<q-btn @click="save"><q-icon icon="save" class="mr-2" /> Save</q-btn>-->
        <q-btn @click="clear" fill="outline">
            <q-icon icon="delete" class="mr-2" /> Clear
        </q-btn>
    </div>
</template>

<script>
import {
    toastController
} from '@ionic/vue';
import SignaturePad from 'signature_pad';
export default {
    data() {
        return {
            elemId: null,
            screenWidth: "0",
            signaturePad: null,
        }
    },
    mounted() {
        this.elemId = this.$_.uniqueId('canvas_');
        this.screenWidth = (window.screen.width - 70)
        this.$nextTick(() => {
            var canvas = document.getElementById(this.elemId);
            this.signaturePad = new SignaturePad(canvas, {
                minWidth: 1,
                maxWidth: 2,
            });
        });
    },
    methods: {
        save() {
            this.openToast();
            const data = this.signaturePad.toDataURL("image/jpeg");
            this.$emit('signature-saved', data);
        },
        clear() {
            this.signaturePad.clear();
        },
        async openToast() {
            const toast = await toastController
                .create({
                    message: 'Signature Saved.',
                    duration: 2000,
                    position: 'top',
                })
            return toast.present();
        },
    }
}
</script>

<style lang="scss" scoped>
canvas {
    display: block;
    position: relative;
}
</style>
