<template>
  <ion-page>
    <ion-header :translucent="false">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title class="font-bold">Job List</ion-title>
        <span slot="end" class="pr-5">
          <user-chip></user-chip>
        </span>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Blank</ion-title>
        </ion-toolbar>
      </ion-header>

      <div id="container">
        <ion-searchbar class="mt-2 mb-0" color="white" @ion-input="Search"></ion-searchbar>
        <div v-if="jobs.length > 0">
          <div class="grid grid-cols-3 mt-0">
            <q-card v-for="job in jobs" :key="job.id" @click="GoTo(job.id)">
              <template #main>
                <div class="text-center">
                  <h5 class="font-bold" style="font-weight: 700;">{{ job.id }}</h5>
                  <ul>
                    <li class="font-semibold">{{ job.name }}</li>
                    <li>{{ job.VRVrm }}</li>
                    <li class="text-xs">{{ job.VRMake }} {{ job.VRModel }}</li>
                    <li class="text-xs" v-if="job.phonenumber !== 0">{{ job.phonenumber }}</li>
                    <li class="text-xs">{{ job.email }}</li>
                  </ul>
                </div>
              </template>
            </q-card>
          </div>
        </div>
        <div v-else class="text-center">
          <h4>No Jobs Found</h4>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSearchbar,
  IonButtons,
  IonBackButton,
  loadingController
} from '@ionic/vue';
import * as Sentry from '@sentry/capacitor';
import {
  defineComponent
} from 'vue';

export default defineComponent({
  name: 'Main',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSearchbar,
    IonButtons,
    IonBackButton
  },
  data() {
    return {
      loading: null,
      isLoading: false,
      type: null,
      jobs: [],
      allJobs: [],
      canClick: true,
    }
  },
  mounted() {
    this.GetJobs(this.$route.params.type);
  },
  methods: {
    async GetJobs(type) {
      await this.presentLoading();
      this.type = type;
      this.$axios.get(this.$apiUrl + '/v2/checklist/jobs-type?type=' + type).then(async (resp) => {
        this.jobs = resp.data
        this.allJobs = resp.data
        await this.loading.dismiss()
      }).catch(async (err) => {
        Sentry.captureMessage(err);
        await this.loading.dismiss()
      })
    },
    GoTo(id) {
      if (this.isLoading) return;
      this.isLoading = true;
      if (this.type === 'mng') {
        this.$router.push({
          path: `/checklists/prework/${id}`
        })
      } else if (this.type === 'rt') {
        this.$router.push({
          path: `/checklists/roadtest/${id}`
        })
      } else if (this.type === 'wc') {
        this.$router.push({
          path: `/checklists/workshop/${id}`
        })
      } else if (this.type === 'epv') {
        this.$router.push({
          path: `/checklists/extramedia/${id}`
        })
      } else if (this.type === 'pwc') {
        this.$router.push({
          path: `/checklists/postwork/${id}`
        })
      } else if (this.type === 'frt') {
        this.$router.push({
          path: `/checklists/finalroadtest/${id}`
        })
      }
      this.isLoading = false;
    },
    Search(e) {
      var val = e.target.value
      if (val !== "") {
        var arr = this.allJobs.filter((item) => {
          var str = item.id + "|" + item.name + "|" + item.VRVrm + "|" + item.VRMake + "|" + item.VRModel + "|" + item.phonenumber + "|" + item.email;
          return val.toLowerCase().split(' ').every(v => str.toLowerCase().includes(v))
        })
        this.jobs = arr
      } else {
        this.jobs = this.allJobs
      }
    },
    async presentLoading() {
      this.loading = await loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Please wait...'
      });
      await this.loading.present();
    },
  }
});
</script>

<style>

</style>
