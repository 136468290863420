import {defineStore} from "pinia";

const initialState = {
    reg: '',
    name: '',
    checkCompletedBy: '',
    folderId: '',
    bookingFormID: '',
    notes: '',
    hasMileage: false,
    serviceBookStamped: 'No',
    mileageIn: '',
    mileageOut: '',
    nextServiceDueDate: null,
    nextServiceDueDistance: '',
    faultCodeScanComplete: [],
    serviceLightReset: "No",
    lowFluidsToppedUp: '',
    oldParts: '',
    engineCover: '',
    undertray: '',
    checkFansPluggedIn: "No",
    lightsOnDashboardCheck: '',
    cycleGears: '',
    cycleGearsDesc: '',
    isChecked: [],
    isDoubleChecked: [],
    torqueWheels: [],
    isCompleted: [],
    keyLocation: '',
    mechanicSig: '',
    mechanicSig2: '',
    dashLights: [],
    photos: [],
    exteriorPhotos: [],
}
export const usePostWorkStore = defineStore('postWorkStore',{
    state: () => ({ ...initialState }),
    actions: {
        async clearAll() {
            localStorage.removeItem('postWorkStore')
            Object.assign(this, {...initialState})
        }
    },
    persist: false,
});
