<template>
    <div class="flex items-center gap-3 mb-3">
        <h3 class="text-xl w-64">{{media.text}}:</h3>
        <q-btn @click="openCameraImage(index, $event)">
            <i class="ic ic-camera pointer-events-none"></i>
            <input ref="imgFileInput" class="h-0 w-0" type="file" capture="user" accept="image/*" @change="UpdateImgData(index, $event)">
            <span class="ml-2" v-if="media.image">
                Image Taken
                <span class="ml-2" v-if="imageCount(photos) > 0">({{imageCount(photos)}})</span>
            </span>
        </q-btn>
    </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
const props = defineProps(['img', 'type', 'jobid', 'photos']);
const emits = defineEmits(['imageTaken']);
const imgFileInput = ref(null);
const media = ref({...props.img});
const openCameraImage = () => {
    imgFileInput.value.click()
}
const UpdateImgData = (index, event) => {
    const obj = {
        id: media.value.id ?? "",
        name: media.value.text ?? "image",
        image: event.target.files[0],//this.imgPreview,
        type: props.type
    }

    media.value.image = obj.image;
    saveImage(obj.image, obj.name);
    emits('imageTaken', obj)
    imgFileInput.value.value = null;
}

const saveImage = (imageSrc, name) => {
    const createEl = document.createElement("a");
    createEl.id = 'download-link';
    document.body.append(createEl);
    const downloadLink = document.getElementById('download-link');
    downloadLink.href = URL.createObjectURL(imageSrc);
    downloadLink.download = props.jobid + "-" + name + '-' + hashID(6) + '.jpg';
    downloadLink.click();
    downloadLink.remove();
}
const hashID = size => {
    const MASK = 0x3d
    const LETTERS = 'abcdefghijklmnopqrstuvwxyz'
    const NUMBERS = '1234567890'
    const charset = `${NUMBERS}${LETTERS}${LETTERS.toUpperCase()}`.split('')

    const bytes = new Uint8Array(size)
    crypto.getRandomValues(bytes)

    return bytes.reduce((acc, byte) => `${acc}${charset[byte & MASK]}`, '')
}

const imageCount = (imgs) => {
    return imgs.filter(el => el.name === props.img.text).length
}
</script>
