import {defineStore} from "pinia";
const initialState = {
    reg: '',
    name: '',
    notes: '',
    extraPhotos: [],
    extraVideos: [],
}
export const useExtraPhotosVideosStore = defineStore('extraPhotosVideosStore',{
    state: () => ({ ...initialState }),
    actions: {
        async clearAll() {
            localStorage.removeItem('extraPhotosVideosStore')
            Object.assign(this, {...initialState})
        }
    },
    persist: false,
});
