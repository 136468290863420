<template>
    <div class="accordion">
        <div class="header font-semibold" v-on:click="toggle">
            <q-icon icon="info" class="mr-2 text-red-500"></q-icon>
            <slot name="header">{{title}}</slot>
            <q-icon icon="expand_more" class="header-icon" :class="{ rotate: show }"></q-icon>
        </div>
        <transition name="accordion" v-on:before-enter="beforeEnter" v-on:enter="enter" v-on:before-leave="beforeLeave" v-on:leave="leave">
            <div class="body" v-show="show">
                <div class="body-inner">
                    <slot></slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "Help Text"
        }
    },
    data: () => ({
        show: false
    }),
    methods: {
        toggle: function () {
            this.show = !this.show;
        },
        beforeEnter: function (el) {
            el.style.height = '0';
        },
        enter: function (el) {
            el.style.height = el.scrollHeight + 'px';
        },
        beforeLeave: function (el) {
            el.style.height = el.scrollHeight + 'px';
        },
        leave: function (el) {
            el.style.height = '0';
        }
    }
}
</script>

<style lang="scss">
.accordion {
    max-width: 100%;
    margin-bottom: 20px;
    background-color: var(--ion-color-light-tint);
    border-radius: 6px;
    border: 1px solid var(--ion-color-border)
}

.accordion .header {
    height: 40px;
    line-height: 40px;
    padding: 0 40px 0 8px;
    position: relative;
    color: var(--ion-color-text);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.accordion .header-icon {
    position: absolute;
    top: 5px;
    right: 8px;
    transform: rotate(0deg);
    transition-duration: 0.3s;
}

.accordion .body {
    /*   display: none; */
    overflow: hidden;
    background-color: var(--ion-card-color-tint);
    border-top: 1px solid var(--ion-color-light-shade);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: 150ms ease-out;
}

.accordion .body-inner {
    padding: 8px;
    overflow-wrap: break-word;
    /*   white-space: pre-wrap; */
}

.accordion .header-icon.rotate {
    transform: rotate(180deg);
    transition-duration: 0.3s;
}
</style>
