<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title class="font-bold">Darkside Developments</ion-title>
                <!--<ion-buttons slot="end">
                    <ion-button @click="openPopover">
                        <i class="ic ic-ellipsis-v text-xl"></i>
                    </ion-button>
                </ion-buttons>-->
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div id="container">
                <div class="min-h-screen h-full">
                    <div class="mt-3 grid grid-cols-5 gap-1">
                        <q-card v-for="operator in operators" :key="operator.id" @click="SelectOperator(operator, $event)">
                            <template #main class="pointer-events-none">
                                <div class="flex items-center justify-center flex-wrap pointer-events-none">
                                    <ion-avatar>
                                        <img :src="operator.img">
                                    </ion-avatar>
                                    <label class="block font-bold mt-2 w-full text-center">{{operator.label}}</label>
                                </div>
                            </template>
                        </q-card>
                    </div>
                    <transition name="pop" appear>
                        <pin-pad v-if="showPinPad" :user="selectedUser" @authed="isAuthed"></pin-pad>
                    </transition>
                </div>
                <transition name="fade" appear>
                    <div class="pin-modal-bg" v-if="showPinPad" @click="showPinPad = false"></div>
                </transition>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    //IonButton,
    //IonButtons,
    IonToolbar,
    IonAvatar,
    popoverController
} from '@ionic/vue';
import {
    defineComponent
} from 'vue';
import SetPop from '../components/SettingsPopover.vue'
import {useMainStore} from "@/store/mainStore";
export default defineComponent({
    name: 'Home',
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
        IonAvatar,
        //IonButton,
        //IonButtons,
    },
    data() {
        return {
            type: null,
            operators: [],
            showPinPad: false,
            selectedUser: null,
            forms: {
                meet_greet: false,
                road_test: false,
                workshop_sheet: false,
                evp_sheet: false,
                post_work_sheet: false,
                final_road_test: false
            },
            containerHeight: 0,
            elemHeight: 0,
            jobs: []
        }
    },
    mounted() {
        this.GetOperators();
        /*this.$nextTick(() => {
            this.containerHeight = window.innerHeight - 56
            this.elemHeight = 368 //this.containerHeight/3
        });

        this.GetOperators();
        if (useMainStore().authUser) {
            this.GetAllowedForms()
        }*/
    },
    methods: {
        GetJobs(type) {
            this.type = type;
            this.$axios.get(this.$apiUrl + '/v2/checklist/jobs-type?type=' + type).then((resp) => {
                console.log(resp.data)
                this.jobs = resp.data
            }).catch((err) => {
                console.log(err)
            })
        },
        GetOperators() {
            this.operators = []
            this.$axios.get(this.$apiUrl + '/api/operators').then((resp) => {
                resp.data.forEach(element => {
                    this.operators.push({
                        value: element.id,
                        label: element.nicename,
                        img: element.image_path || "https://darkside-developments.com/images/DSD.png"
                    })
                });
            })
        },
        GetAllowedForms() {
            this.$axios.get(this.$apiUrl + '/v2/checklist/user/forms/allowed?id=' + useMainStore().authUser.value).then((resp) => {
                if (resp.data.success) {
                    var d = resp.data.data;
                    this.forms.meet_greet = d.meet_greet
                    this.forms.road_test = d.road_test
                    this.forms.workshop_sheet = d.workshop_sheet
                    this.forms.evp_sheet = d.evp_sheet
                    this.forms.post_work_sheet = d.post_work_sheet
                    this.forms.final_road_test = d.final_road_test
                }
            }).catch(err => {

            })
        },
        SelectOperator(operator, event) {
            this.selectedUser = operator
            this.showPinPad = true;
        },
        isAuthed(e) {
            this.showPinPad = false
            useMainStore().setIsAuthed(true);
            useMainStore().setAuthUser(e.user);
            //this.$store.commit('mainStore/setIsAuthed', true)
            //this.$store.commit('mainStore/setAuthUser', e.user)
            this.$nextTick(() => {
                this.$router.push('/checklists')
            })
        },
        async openPopover(ev) {
            const popover = await popoverController.create({
                component: SetPop,
                cssClass: 'my-custom-class',
                event: ev,
                translucent: true,
                componentProps: {
                    onClick: (e) => {
                        var val = e.target.textContent;
                        console.log(val)
                        switch (val) {
                            case "Dark Mode":
                                document.getElementsByTagName('body')[0].classList.add('dark')
                                break;
                            case "Light Mode":
                                document.getElementsByTagName('body')[0].classList.remove('dark')
                                break;
                            default:
                                break;
                        }
                        popover.dismiss();
                    },
                },
            })
            await popover.present();

            const {
                role
            } = await popover.onDidDismiss();
            console.log('onDidDismiss resolved with role', role);
        },
    },
    watch: {
        '$store.state.main.authUser'(val) {
            if (val) {
                //this.GetAllowedForms();
                //this.containerHeight = document.getElementById('container').offsetHeight
                //this.elemHeight = this.containerHeight / 3
            }
        }
    }
});
</script>

<style lang="scss" scoped>
.pin-modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
}

.pin-modal {
    position: fixed;
    top: 0;
    z-index: 99;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .pin-modal-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 200px;
        background: white;
        border-radius: 10px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    }
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .4s linear;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.pop-enter-active,
.pop-leave-active {
    transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
    opacity: 0;
    transform: scale(0.3) translateY(-50%);
}
</style>
