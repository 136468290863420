<template>
    <div>
        <div class="flex items-center gap-10">
            <div class="w-2/6">
                <label class="font-semibold text-lg">{{title}}</label>
                <div v-if="$slots.warning"><slot name="warning"></slot></div>
            </div>
            <div class="w-4/6">
                <slot></slot>
            </div>
        </div>
        <div v-if="$slots.after" class="mt-2"><slot name="after"></slot></div>
    </div>
</template>
<script>
export default {
    props: ['title']
}
</script>

<style>

</style>