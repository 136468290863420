<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="60%" height="60%" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
        <g class="progress-container">
            <circle class="progress" :stroke="color" fill="none" stroke-miterlimit="10" cx="32" cy="32" r="30" />
        </g>
        <circle class="track" fill="none" :stroke="trackColor" :stroke-opacity="trackOpacity" stroke-miterlimit="10" cx="32" cy="32" r="30" />
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "#1E76E3"
        },
        trackColor: {
            type: String,
            default: "#000"
        },
        trackOpacity: {
            type: Number,
            default: 0.1
        }
    }
}
</script>

<style lang="scss">
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Helper Methods
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
@use 'sass:math';
@function degToPerc($degree) {
    @return math.div($degree, 360); /*$degree / 360;*/
}

@function degToLength($degree) {
    @return degToPerc($degree) * $circumference;
}

@function strokeLength($degress) {
    @return degToLength($degress),
    ($circumference - degToLength($degress));
}

@function getOffset($degrees) {
    // start dash from top, not right
    $strokeBaseOffset: degToLength(90);

    @return $strokeBaseOffset - degToLength($degrees);
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Variables
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

$pi: 3.14159265359;
$radius: 30px; // px, r (radius) attribute of <circle>
$circumference: $radius * $pi * 2;
$min: 90; // degrees

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Elements
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.progress-container {
    transform-origin: 32px 32px;
    animation: rotate90 1s linear infinite normal;
}

.progress {
    animation: progress 1s linear infinite normal;
    //stroke: #1E76E3;
    stroke-dasharray: strokeLength($min);
    stroke-dashoffset: getOffset(0);
    stroke-width: 4;

    // SVG goodnes
    stroke-linecap: round;
}

.track {
    //stroke: #000;
    //stroke-opacity: 0.1;
    stroke-width: 4;
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Animation
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

@keyframes progress {
    0% {
        // stroke-dasharray: strokeLength($min);
    }

    10% {
        stroke-dasharray: strokeLength(180);
    }

    25% {
        stroke-dasharray: strokeLength(279);
    }

    32% {
        stroke-dasharray: strokeLength(298.95);
        stroke-dashoffset: getOffset(0);
    }

    54% {
        stroke-dasharray: strokeLength(181.6499996);
        stroke-dashoffset: getOffset(180);
    }

    85% {
        stroke-dasharray: strokeLength($min);
        stroke-dashoffset: getOffset(270);
    }

    100% {
        stroke-dasharray: strokeLength($min);
        stroke-dashoffset: getOffset(270);
    }
}

@keyframes rotate90 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(90deg);
    }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Misc
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
